import { createEffect, createEvent, createStore, forward } from 'effector';
import { CITIES } from '../constants';
import { storeRequest } from '../libs';
import { ICity } from '../types';

export const $cities = createStore<ICity[]>([]);

export const $selectCities = $cities.map((array) =>
  array.map(({ id, name }) => ({
    text: name,
    value: id,
  }))
);

export const getCities = createEvent();

export const getCitiesFx = createEffect(async () => {
  const res = await storeRequest(CITIES);

  return res;
});

forward({
  from: getCities,
  to: getCitiesFx,
});

$cities.on(getCitiesFx.doneData, (_, { data }) => data);
