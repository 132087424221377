import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import s from './SectionCalendar.module.scss';
import { Button, Form, FormItem, Input, ISelectOption, Select } from '../../ui';
import {
  newEntryFormData,
  newEntryValidationSchema,
  TFormData,
} from './helpers';
import { ISectionGroup } from '../../types';
import { GENDER_OPTIONS, SECTION_GROUP_CHILDREN } from '../../constants';
import {
  clearPhoneNumber,
  formatStringToPhoneNumber,
  request,
} from '../../libs';

interface Props {
  activeGroup: number;
  groupsOptions: ISelectOption[];
  sectionGroups: ISectionGroup[];
  getSectionGroupChildren: () => void;
  toggleModal: () => void;
}

export const NewEntryForm: FC<Props> = ({
  groupsOptions,
  sectionGroups,
  activeGroup,
  getSectionGroupChildren,
  toggleModal,
}) => {
  const [blocking, setBlocking] = useState(false);
  const activeGroupObj = groupsOptions.find(
    ({ value }) => value === activeGroup
  );
  const activeSectionGroup = sectionGroups.find(
    ({ name }) => name === activeGroupObj?.text
  );
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      lastName: '',
      firstName: '',
      middleName: '',
      phone: '',
      gender: `${GENDER_OPTIONS[0].value}`,
    },
    onSubmit,
    validationSchema: newEntryValidationSchema,
  });

  async function onSubmit({
    gender,
    phone,
    ...formData
  }: TFormData): Promise<void> {
    try {
      setBlocking(true);

      await request({
        url: SECTION_GROUP_CHILDREN,
        options: {
          body: JSON.stringify({
            sectionGroup: {
              id: activeSectionGroup?.id,
              name: activeSectionGroup?.name,
            },
            child: {
              gender: Number(gender),
              phone: clearPhoneNumber(phone),
              ...formData,
            },
          }),
        },
        successNotify: 'Запись добавлена',
      })();

      toggleModal();
      getSectionGroupChildren();
    } catch ({ message }) {
      console.error(message);
    } finally {
      setBlocking(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} blocking={blocking} className={s.new_entry}>
      {newEntryFormData.map(({ reactId, name, label, type }) => {
        if (type === 'select') {
          return (
            <FormItem
              key={reactId}
              label={label}
              error={!!errors[name] && !!touched[name]}
              helperText={
                !!errors[name] && !!touched[name] && (errors[name] as string)
              }
            >
              <Select
                onChange={handleChange}
                value={values[name]}
                options={GENDER_OPTIONS}
                name={name}
                error={!!errors[name] && !!touched[name]}
              />
            </FormItem>
          );
        }

        if (name === 'phone') {
          return (
            <FormItem
              label="Телефон для связи"
              error={!!errors.phone && touched.phone}
              helperText={!!errors.phone && touched.phone && errors.phone}
            >
              <Input
                name="phone"
                error={!!errors.phone && touched.phone}
                value={formatStringToPhoneNumber(values.phone) as string}
                onChange={handleChange}
                maxLength={18}
              />
            </FormItem>
          );
        }

        return (
          <FormItem
            key={reactId}
            label={label}
            error={!!errors[name] && !!touched[name]}
            helperText={
              !!errors[name] && !!touched[name] && (errors[name] as string)
            }
          >
            <Input
              onChange={handleChange}
              value={values[name]}
              name={name}
              type="text"
              error={!!errors[name] && !!touched[name]}
            />
          </FormItem>
        );
      })}
      <Button type="submit" fullWidth>
        Добавить новую запись
      </Button>
    </Form>
  );
};
