import { useStore } from 'effector-react';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { $selectDirections, $selectDisciplines } from '../../store';
import { REQUIRED_FIELD, SECTIONS } from '../../constants';

import { FormItem, Input, Button, Select, Form } from '../../ui';

import s from './Main.module.scss';
import { request } from '../../libs';
import { getSections } from '../../store/sections';
import { ISection } from '../../types';

const validationSchema = yup.object({
  name: yup.string().required(REQUIRED_FIELD),
  discipline: yup.string().required(REQUIRED_FIELD),
  direction: yup.string().required(REQUIRED_FIELD),
});

type FormData = {
  name: string;
  discipline: string;
  direction: string;
};

export const NewSectionForm: FC = () => {
  const [blocking, setBlocking] = useState(false);
  const directions = useStore($selectDirections);
  const disciplines = useStore($selectDisciplines);
  const history = useHistory();

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name: '',
      discipline: `${disciplines?.[0]?.value}`,
      direction: `${directions?.[0]?.value}`,
    },
    onSubmit,
    validationSchema,
  });

  async function onSubmit({
    name,
    discipline,
    direction,
  }: FormData): Promise<void> {
    try {
      setBlocking(true);
      const currentDiscipline = disciplines.find(
        ({ value }) => value === Number(discipline)
      );
      const currentDirection = directions.find(
        ({ value }) => value === Number(direction)
      );

      const body = {
        name,
        discipline: {
          id: Number(discipline),
          name: currentDiscipline?.text,
        },
        direction: {
          id: Number(direction),
          name: currentDirection?.text,
        },
      };

      const data = await request<ISection>({
        url: SECTIONS,
        options: {
          body: JSON.stringify(body),
        },
        delay: 1000,
        successNotify: 'Услуга успешно создана',
      })();

      getSections();

      setBlocking(false);

      history.push(`/section/${data?.id}`);
    } catch ({ message }) {
      console.error(message);

      setBlocking(false);
    }
  }

  return (
    <Form blocking={blocking} className={s.form} onSubmit={handleSubmit}>
      <FormItem
        error={!!errors.name && touched.name}
        label="Название услуги"
        helperText={!!errors.name && touched.name && errors.name}
      >
        <Input
          value={values.name}
          name="name"
          error={!!errors.name && touched.name}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Направление"
        error={!!errors.direction && touched.direction}
        helperText={!!errors.direction && touched.direction && errors.direction}
      >
        <Select
          value={values.direction}
          name="direction"
          onChange={handleChange}
          options={directions}
        />
      </FormItem>
      <FormItem
        label="Дисциплина"
        error={!!errors.discipline && touched.discipline}
        helperText={
          !!errors.discipline && touched.discipline && errors.discipline
        }
      >
        <Select
          value={values.discipline}
          name="discipline"
          onChange={handleChange}
          options={disciplines}
        />
      </FormItem>
      <Button type="submit" fullWidth>
        Добавить услугу
      </Button>
    </Form>
  );
};
