import { createEffect, createEvent, createStore, forward } from 'effector';
import { TEACHERS } from '../constants';
import { storeRequest } from '../libs';
import { ITeacher } from '../types';

export const $teachers = createStore<ITeacher[]>([]);

export const $selectTeachers = $teachers.map((array) =>
  array.map(({ id, firstName, lastName, middleName }) => ({
    text: `${lastName} ${firstName} ${middleName}`,
    value: id,
  }))
);

export const getTeachers = createEvent();

export const getTeachersFx = createEffect(async () => {
  const res = await storeRequest(TEACHERS);

  return res;
});

forward({
  from: getTeachers,
  to: getTeachersFx,
});

$teachers.on(getTeachersFx.doneData, (_, { data }) => data);
