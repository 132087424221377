import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Route, Switch } from 'react-router-dom';
import { $teachers, getTeachersFx } from '../../store';
import {
  Button,
  EmptyPage,
  MainTemplate,
  Spinner,
  WithSidebar,
} from '../../ui';
import { Teacher, NewTeacherForm, Header } from '../../components';
import s from './TeacherPage.module.scss';
import { Modal, useModal } from '../../libs';
import { TeacherPage } from './TeacherPage';

export const Teachers: FC = () => {
  const teachers = useStore($teachers);
  const { isActive, toggleModal } = useModal();
  const loading = useStore(getTeachersFx.pending);

  return (
    <MainTemplate withSliderTabs header={<Header />}>
      {loading ? (
        <Spinner />
      ) : (
        <Switch>
          <Route path="/teachers/:id">
            <TeacherPage />
          </Route>
          <Route path="/teachers">
            {teachers?.length > 0 ? (
              <WithSidebar
                sidebar={
                  <Button fullWidth onClick={toggleModal}>
                    Добавить преподавателя
                  </Button>
                }
                className={s.teachers_wrap}
              >
                <div className={s.teachers}>
                  {teachers.map((teacher) => (
                    <Teacher key={teacher.id} {...teacher} />
                  ))}
                </div>
              </WithSidebar>
            ) : (
              <EmptyPage
                title="У вас пока нет преподавателей"
                subtitle="Чтобы добавить преподавателя, нажмите кнопку ниже
и заполните небольшую форму"
                actionsButtonText="Добавить преподавателя"
                actionButtonAction={toggleModal}
              />
            )}
            <Modal
              title="Новый преподаватель"
              isActive={isActive}
              toggleModal={toggleModal}
            >
              <NewTeacherForm onSuccess={() => toggleModal()} />
            </Modal>
          </Route>
        </Switch>
      )}
    </MainTemplate>
  );
};
