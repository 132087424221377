import React, { FC, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Teachers } from './pages/TeacherPage/Teachers';
import { PrivateRoute, useAuth } from './libs';
import { ContactsPage, Login, Main, SectionPage } from './pages';
import { getData } from './store';
import 'react-toastify/dist/ReactToastify.css';

export const App: FC = () => {
  const { isAuthorized } = useAuth();

  useEffect(() => {
    if (isAuthorized) {
      getData();
    }
  }, [isAuthorized]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <PrivateRoute path="/" exact>
          <Main />
        </PrivateRoute>
        <PrivateRoute path="/section/:id">
          <SectionPage />
        </PrivateRoute>
        <PrivateRoute path="/teachers">
          <Teachers />
        </PrivateRoute>
        <PrivateRoute path="/contacts">
          <ContactsPage />
        </PrivateRoute>
        <Route path="/login">
          <Login />
        </Route>
      </Switch>
    </>
  );
};
