import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import s from './Header.module.scss';
import { ReactComponent as Logo } from '../../assets/images/Logo-header.svg';
import { Typography, Avatar } from '../../ui';
import { useAuth } from '../../libs';

export const Header: FC = () => {
  const { username } = useAuth();

  return (
    <div className={s.wrap}>
      <div className={s.header}>
        <Link to="/">
          <Logo />
        </Link>
        <div className={s.info}>
          <div className={s.column}>
            <Typography variant="body1">Добро пожаловать,</Typography>
            <Typography variant="body2" color="link">
              {username}
            </Typography>
          </div>
          <Avatar />
        </div>
      </div>
    </div>
  );
};
