import React, { AllHTMLAttributes, FC } from 'react';
import cn from 'classnames';
import { Typography } from '../Typography';
import s from './Checkbox.module.scss';

interface Props extends AllHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  className?: string;
}

export const Checkbox: FC<Props> = ({
  label,
  error = false,
  className,
  ...props
}) => {
  return (
    <label className={cn(s.root, className)}>
      {label && (
        <Typography
          color={error ? 'error' : 'secondary'}
          variant="body2"
          className={s.label}
        >
          {label}
        </Typography>
      )}
      <input className={s.input} type="checkbox" {...props} />
    </label>
  );
};
