import { createEffect, createEvent, createStore, forward } from 'effector';
import { ORGANIZATION } from '../constants';
import { storeRequest } from '../libs';
import { IOrganization } from '../types';

export const $organization = createStore<IOrganization | null>(null);

export const getOrganization = createEvent();

export const getOrganizationFx = createEffect(async () => {
  const res = await storeRequest(ORGANIZATION);

  return res;
});

forward({
  from: getOrganization,
  to: getOrganizationFx,
});

$organization.on(getOrganizationFx.doneData, (_, data) => data);
