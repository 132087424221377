import React, { FC } from 'react';
import s from './EmptyPage.module.scss';
import { Button, Typography } from '../../atoms';

interface Props {
  title: string;
  subtitle: string;
  actionsButtonText?: string;
  actionButtonAction?: (...args: any[]) => void;
}

export const EmptyPage: FC<Props> = ({
  title,
  subtitle,
  actionButtonAction,
  actionsButtonText,
}) => {
  return (
    <div className={s.root}>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1" color="secondary">
        {subtitle}
      </Typography>
      {actionButtonAction && actionsButtonText && (
        <Button onClick={actionButtonAction}>{actionsButtonText}</Button>
      )}
    </div>
  );
};
