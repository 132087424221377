import React, { memo, FC } from 'react';
import cn from 'classnames';

import s from './Select.module.scss';

export interface ISelectOption extends Record<string, any> {
  value: any;
  text: string | number;
}

interface ISelect extends Record<string, any> {
  error?: boolean;
  options?: ISelectOption[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}
const CaretIcon: FC = () => (
  <div className={s.caretIcon}>
    <span />
    <span />
  </div>
);

export const Select: React.FC<ISelect> = memo(
  ({ label, options = [], disabled, fullWidth, error, ...rest }) => (
    <div
      className={cn(s.wrap, {
        [s.wrap_fullWidth]: fullWidth,
      })}
    >
      <select
        className={cn(s.select, {
          [s.select_fullWidth]: fullWidth,
          [s.select_error]: error,
        })}
        disabled={disabled}
        {...rest}
      >
        {label && (
          <option aria-label={label} value="">
            {label}
          </option>
        )}
        {options.map(({ value: optionValue, text }) => (
          <option key={optionValue} value={optionValue}>
            {text}
          </option>
        ))}
      </select>
      <CaretIcon />
    </div>
  )
);
