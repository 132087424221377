import React, { FC } from 'react';

import s from './BlockUI.module.scss';
import { Spinner } from '../../atoms';

interface BlockUIProps {
  blocked: boolean;
  borderRadius?: number;
  withoutBorderRadius?: boolean;
}

export const BlockUI: FC<BlockUIProps> = ({
  children,
  blocked,
  borderRadius = 16,
  withoutBorderRadius = false,
}) => {
  return (
    <div
      className={s.root}
      style={{
        borderRadius: withoutBorderRadius ? undefined : `${borderRadius}px`,
      }}
    >
      {blocked && (
        <div className={s.blocker}>
          <Spinner />
        </div>
      )}
      {children}
    </div>
  );
};
