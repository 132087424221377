import React, { memo } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Tooltip } from '@material-ui/core';
import { Button } from '../Button';
import { ReactComponent as CloseCross } from '../../../assets/images/close-cross.svg';
import s from './ImageInput.module.scss';

interface Props {
  maxNumber?: number;
  images: any[];
  setImages: (...args: any[]) => void;
  buttonText?: string;
  fullWidth?: boolean;
  error?: boolean;
}

export const ImageInput = memo<Props>(
  ({
    maxNumber = 1,
    images = [],
    setImages,
    buttonText = 'Выбрать фото',
    fullWidth = true,
    error,
  }) => {
    const onChange = (imageList: ImageListType): void => {
      setImages(imageList as never[]);
    };

    return (
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="dataUrl"
      >
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove }) => (
          <div className={s.root}>
            {imageList.length < maxNumber && (
              <Button
                fullWidth={fullWidth}
                variant={error ? 'error' : 'outline'}
                onClick={onImageUpload}
                disabled={imageList.length >= maxNumber}
              >
                {buttonText}
              </Button>
            )}
            {imageList.map(({ dataUrl, file }, index) => (
              <div key={dataUrl} className={s.image}>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  src={dataUrl}
                  alt={file?.name ?? ''}
                  onClick={() => onImageUpdate(index)}
                />
                <div
                  className={s.delete}
                  role="button"
                  tabIndex={0}
                  onClick={() => onImageRemove(index)}
                >
                  <Tooltip title="Удалить фото">
                    <CloseCross />
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    );
  }
);
