import React, { FC } from 'react';
import cn from 'classnames';
import s from './Tag.module.scss';

interface Props {
  className?: string;
}

export const Tag: FC<Props> = ({ children, className }) => {
  return <div className={cn(s.root, className)}>{children}</div>;
};
