import React, { FC, useState } from 'react';

import { RoundButton, Typography } from '../../atoms';

import s from './GroupList.module.scss';
import { ISectionGroup } from '../../../types';
import { TimeSquare } from '../TimeSquare';
import { WEEK_DAYS_LONG } from '../../../constants';

interface IGroupList {
  groups: ISectionGroup[];
}

export const GroupList: FC<IGroupList> = ({ groups }) => {
  const [activeGroup, setActiveGroup] = useState(groups?.[0]?.name);

  const onActiveGroupChange = (name: string): void => {
    setActiveGroup(name);
  };

  return (
    <div className={s.root}>
      <div className={s.groups}>
        {groups.map(({ name, id }) => (
          <RoundButton
            onClick={() => onActiveGroupChange(name)}
            key={id}
            active={name === activeGroup}
          >
            {name}
          </RoundButton>
        ))}
      </div>
      {groups.map(
        ({ name, sectionGroupSchedules }) =>
          name === activeGroup && (
            <div key={name} className={s.schedules}>
              {sectionGroupSchedules?.map(
                ({ id, dayOfWeek, sectionGroupScheduleTimes }) => (
                  <div key={id} className={s.schedules_item}>
                    <Typography>{WEEK_DAYS_LONG[dayOfWeek]}</Typography>
                    <div>
                      {sectionGroupScheduleTimes?.map(
                        ({ id: timesId, startTime, endTime }) => (
                          <div key={timesId} className={s.schedules_times}>
                            <TimeSquare>{startTime}</TimeSquare>
                            {endTime && (
                              <>
                                <span>—</span>
                                <TimeSquare>{endTime}</TimeSquare>
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          )
      )}
    </div>
  );
};
