import React, { FC } from 'react';
import { useStore } from 'effector-react';
import s from './Main.module.scss';
import { Header } from '../../components';
import { MainSidebar } from './Sidebar';
import { $organization, getOrganizationFx } from '../../store/organization';
import { $sections, getSectionsFx } from '../../store/sections';
import {
  MainTemplate,
  WithSidebar,
  SectionInfo,
  OrganisationHeader,
  Spinner,
  EmptyPage,
} from '../../ui';

export const Main: FC = () => {
  const organization = useStore($organization);
  const sections = useStore($sections);
  const organizationLoading = useStore(getOrganizationFx.pending);
  const sectionsLoading = useStore(getSectionsFx.pending);
  const loading = organizationLoading || sectionsLoading;

  return (
    <MainTemplate withSliderTabs header={<Header />}>
      {loading && <Spinner />}
      {organization && !loading && (
        <>
          {organization && <OrganisationHeader {...organization} />}
          <WithSidebar sidebar={<MainSidebar />}>
            <div className={s.wrap}>
              {sections?.map((section) => (
                <SectionInfo key={section.id} {...section} />
              ))}
            </div>
          </WithSidebar>
        </>
      )}
      {!organization && !loading && (
        <EmptyPage
          title="У вас нет организации"
          subtitle="Данные отсутвуют. Возможно, произошла ошибка"
        />
      )}
    </MainTemplate>
  );
};
