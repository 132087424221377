import React, { FC, memo, useCallback, useState } from 'react';
import { useStore } from 'effector-react';
import { useFormik } from 'formik';
import uniqueId from 'lodash/uniqueId';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Typography,
} from '../../ui';
import { validationSchema, TFormData } from '../../components';
import { $selectDisciplines, getTeachers } from '../../store';
import {
  clearPhoneNumber,
  createFioFromObj,
  createInitials,
  formatStringToPhoneNumber,
  Modal,
  request,
  useModal,
} from '../../libs';
import { EMAIL_EXAMPLE, EMAIL_PATTERN, TEACHERS } from '../../constants';
import s from './TeacherPage.module.scss';
import { ITeacher } from '../../types';

interface Props {
  teacher: ITeacher;
}

interface TeacherPhotoProps {
  images: ImageListType;
  onChange: (
    value: ImageListType,
    addUpdatedIndex?: number[] | undefined
  ) => void;
  teacher: ITeacher;
}

const TeacherPhoto = memo<TeacherPhotoProps>(
  ({ images, onChange, teacher }) => {
    return (
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="dataUrl"
      >
        {({ imageList, onImageUpdate }) => (
          <div className={s.root}>
            {imageList.map(({ dataUrl }, index) => (
              <div className={s.avatar}>
                <Avatar
                  key={uniqueId()}
                  variant="L"
                  src={dataUrl}
                  alt={createInitials(
                    teacher.lastName,
                    teacher.firstName,
                    teacher.middleName
                  )}
                />
                <div className={s.content}>
                  <Typography variant="h2">
                    {createFioFromObj(teacher)}
                  </Typography>
                  <Button variant="link" onClick={() => onImageUpdate(index)}>
                    Выбрать другое фото
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    );
  }
);

export const TeacherEditForm: FC<Props> = ({ teacher }) => {
  const history = useHistory();
  const disciplines = useStore($selectDisciplines);
  const [blocking, setBlocking] = useState(false);
  const teachersDiscipline = `${
    teacher?.discipline?.id ?? disciplines?.[0]?.value
  }`;
  const { isActive, toggleModal } = useModal();

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        lastName: teacher.lastName,
        firstName: teacher.firstName,
        middleName: teacher.middleName,
        discipline: teachersDiscipline,
        images: [{ dataUrl: teacher.photo?.url }],
        phone: teacher.phone,
        email: teacher.email,
      },
      onSubmit,
      validationSchema,
    });

  async function onSubmit({
    images,
    phone,
    discipline,
    ...formData
  }: TFormData): Promise<void> {
    try {
      setBlocking(true);

      const data = new FormData();
      const keys = Object.keys(formData);
      // @ts-ignore
      keys.forEach((key) => data.append(key, formData[key]));
      data.append('phone', clearPhoneNumber(phone));
      data.append('photo', images[0].file);
      data.append('disciplineId', discipline);

      await request({
        url: `${TEACHERS}/${teacher.id}`,
        options: {
          method: 'PUT',
          body: data,
        },
        successNotify: 'Форма отправлена успешно',
      })();

      getTeachers();
    } catch ({ message }) {
      console.error(message);
    } finally {
      setBlocking(false);
    }
  }

  const deleteTeacher = async (): Promise<void> => {
    try {
      setBlocking(true);
      await request({
        url: `${TEACHERS}/${teacher.id}`,
        options: {
          method: 'DELETE',
        },
        successNotify: 'Преподаватель удален',
      })();

      getTeachers();

      history.goBack();
    } catch ({ message }) {
      console.error(message);
    } finally {
      setBlocking(false);
    }
  };

  const handleImageChange = useCallback((imageList: any[]): void => {
    setFieldValue('images', imageList);
  }, []);

  return (
    <Form blocking={blocking} className={s.form} onSubmit={handleSubmit}>
      <TeacherPhoto
        images={values.images}
        onChange={handleImageChange}
        teacher={teacher}
      />
      <FormItem
        label="Фамилия"
        error={!!errors.lastName && touched.lastName}
        helperText={!!errors.lastName && touched.lastName && errors.lastName}
      >
        <Input
          value={values.lastName}
          name="lastName"
          error={!!errors.lastName && touched.lastName}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Имя"
        error={!!errors.firstName && touched.firstName}
        helperText={!!errors.firstName && touched.firstName && errors.firstName}
      >
        <Input
          value={values.firstName}
          name="firstName"
          error={!!errors.firstName && touched.firstName}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Отчество"
        error={!!errors.middleName && touched.middleName}
        helperText={
          !!errors.middleName && touched.middleName && errors.middleName
        }
      >
        <Input
          value={values.middleName}
          name="middleName"
          error={!!errors.middleName && touched.middleName}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Дисциплина"
        error={!!errors.discipline && touched.discipline}
        helperText={
          !!errors.discipline && touched.discipline && errors.discipline
        }
      >
        <Select
          value={values.discipline}
          name="discipline"
          onChange={handleChange}
          options={disciplines}
        />
      </FormItem>
      <FormItem
        label="Телефон"
        error={!!errors.phone && touched.phone}
        helperText={!!errors.phone && touched.phone && errors.phone}
      >
        <Input
          value={formatStringToPhoneNumber(values.phone) as string}
          name="phone"
          error={!!errors.phone && touched.phone}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Электронная почта"
        error={!!errors.email && touched.email}
        helperText={!!errors.email && touched.email && errors.email}
      >
        <Input
          value={values.email}
          name="email"
          error={!!errors.email && touched.email}
          onChange={handleChange}
          pattern={EMAIL_PATTERN}
          title={EMAIL_EXAMPLE}
        />
      </FormItem>
      {/* <FormItem */}
      {/*  label="Комментарий" */}
      {/*  error={!!errors.comment && touched.comment} */}
      {/*  helperText={!!errors.comment && touched.comment && errors.comment} */}
      {/* > */}
      {/*  <Textarea */}
      {/*    value={values.comment} */}
      {/*    name="comment" */}
      {/*    error={!!errors.comment && touched.comment} */}
      {/*    onChange={handleChange} */}
      {/*  /> */}
      {/* </FormItem> */}
      <Button type="submit" fullWidth>
        Сохранить изменения
      </Button>
      <Button variant="error" type="button" onClick={toggleModal} fullWidth>
        Удалить преподавателя
      </Button>
      <Modal
        title="Удаление преподавателя"
        isActive={isActive}
        toggleModal={toggleModal}
      >
        <Form blocking={blocking} className={s.modal}>
          <Typography>Вы уверены, что хотите удалить преподавателя?</Typography>
          <Button fullWidth onClick={deleteTeacher}>
            Удалить
          </Button>
          <Button fullWidth variant="error" onClick={toggleModal}>
            Отмена
          </Button>
        </Form>
      </Modal>
    </Form>
  );
};
