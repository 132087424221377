import uniqueId from 'lodash/uniqueId';
import { PEREODICITY_TYPES, RECORD_TYPES } from '../../constants';
import {
  ISectionGroup,
  ISectionGroupSchedule,
  ISectionGroupScheduleTiming,
} from '../../types';
import { parseNumberFromString } from '../../libs';

export const defaultSectionGroups = [
  {
    reactId: uniqueId(),
    name: 'Группа 1',
    sectionGroupSchedules: [],
  },
];

export const scheduleExample = {
  startTime: '',
  endTime: '',
  reactId: uniqueId(),
  sectionGroupSchedule: null,
};

export const createNewScheduleTimes = (): ISectionGroupScheduleTiming => ({
  ...scheduleExample,
  reactId: uniqueId(),
});

export const createNewSectionGroupSchedule = (
  dayOfWeek: number
): ISectionGroupSchedule => ({
  reactId: uniqueId(),
  dayOfWeek,
  sectionGroupId: null,
  sectionGroupScheduleTimes: [scheduleExample],
});

export const recordTypes = RECORD_TYPES.map((type, index) => ({
  text: type,
  value: index,
}));

export const periodicityTypes = PEREODICITY_TYPES.map((type, index) => ({
  text: type,
  value: index,
}));

export const createNewSectionGroup = (
  sectionGroups: ISectionGroup[]
): ISectionGroup => {
  const allGroupsNumbers = sectionGroups.map(({ name }) =>
    parseNumberFromString(name)
  );

  const lastGroupNumber =
    allGroupsNumbers.length > 0 ? Math.max(...allGroupsNumbers) : 0;

  return {
    reactId: uniqueId(),
    name: `Группа ${lastGroupNumber + 1}`,
    sectionGroupSchedules: [],
  };
};

export const WEEK_DAYS = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

export const WEEK_DAYS_NUMBERS = WEEK_DAYS.map((_, index) => index);

export const WEEK_DAYS_OPTIONS = WEEK_DAYS_NUMBERS.map((number) => ({
  text: WEEK_DAYS[number],
  value: number,
}));
