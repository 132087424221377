export function createFio(
  lastName: string,
  firstName: string,
  middleName: string
): string {
  return `${lastName} ${firstName} ${middleName}`;
}

export function createFioFromObj(obj: Record<string, any>): string {
  if (!obj || !obj.lastName || !obj.firstName || !obj.middleName) {
    return '';
  }

  return createFio(obj.lastName, obj.firstName, obj.middleName);
}

export function createInitials(
  lastName: string,
  firstName: string,
  middleName: string
): string {
  return `${lastName[0]} ${firstName[0]} ${middleName?.[0]}`;
}
