import React, { FC, memo } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import uniqueId from 'lodash/uniqueId';
import s from './OrganisationHeader.module.scss';
import { Avatar, Typography } from '../../atoms';
import { IOrganization } from '../../../types';
import {
  formatStringToPhoneLink,
  formatStringToPhoneNumber,
} from '../../../libs';
import { CameraButton } from '../../atoms/Button/CameraButton';

export const OrganisationHeader: FC<IOrganization> = ({
  phone,
  email,
  image,
  name,
  address,
  city,
}) => {
  return (
    <div className={s.row}>
      <Avatar src={image?.url} alt="avatar" variant="L" />
      <div className={s.wrap}>
        <Typography variant="h2" className={s.title1}>
          {name}
        </Typography>
        {city && address && (
          <Typography variant="body2" color="secondary">
            {`${city?.name} ${address}`}
          </Typography>
        )}
        <div className={s.column}>
          {phone && (
            <a
              href={`tel:${formatStringToPhoneLink(phone)}`}
              className={s.phone}
            >
              {formatStringToPhoneNumber(phone)}
            </a>
          )}
          {email && (
            <a href={`mailto:${email}`} className={s.mail}>
              {email}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

interface IChangebleOrganizationHeader extends IOrganization {
  images: ImageListType;
  onChange: (
    value: ImageListType,
    addUpdatedIndex?: number[] | undefined
  ) => void;
}

export const ChangebleOrganizationHeader = memo<IChangebleOrganizationHeader>(
  ({ phone, email, name, address, city, images, onChange }) => {
    return (
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="dataUrl"
      >
        {({ imageList, onImageUpdate }) =>
          imageList.map(({ dataUrl }, index) => (
            <div className={s.row}>
              <div className={s.avatar}>
                <Avatar key={uniqueId()} variant="L" src={dataUrl} />
                <CameraButton
                  className={s.camera}
                  onClick={() => onImageUpdate(index)}
                />
              </div>
              <div className={s.wrap}>
                <Typography variant="h2" bold className={s.title1}>
                  {name}
                </Typography>
                {city && address && (
                  <Typography variant="body2" color="secondary">
                    {`${city?.name} ${address}`}
                  </Typography>
                )}
                <div className={s.column}>
                  {phone && (
                    <a
                      href={`tel:${formatStringToPhoneLink(phone)}`}
                      className={s.phone}
                    >
                      {formatStringToPhoneNumber(phone)}
                    </a>
                  )}
                  {email && (
                    <a href={`mailto:${email}`} className={s.mail}>
                      {email}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))
        }
      </ImageUploading>
    );
  }
);
