import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import s from './router-slider-tabs.module.scss';

interface IRouterSlilderTabs {
  /**
   * Массив ссылок {to, title}
   */
  links: {
    to: string;
    title: string;
  }[];
  extraButtons?: ReactNode;
}

export const RouterSlilderTabs: FC<IRouterSlilderTabs> = ({
  links,
  extraButtons,
}) => {
  const wrapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const wrap = wrapRef.current;
    if (wrap) {
      const activeElem = wrap.querySelector('a[aria-current="page"]');

      activeElem?.scrollIntoView({ block: 'center', inline: 'end' });
    }
  }, []);

  return (
    <div className={s.wrap} ref={wrapRef}>
      {links.map(({ to, title }) => (
        <NavLink
          exact
          className={s.item}
          activeClassName={s.item_active}
          to={to}
          key={to}
        >
          {title}
        </NavLink>
      ))}
      {extraButtons && <div className={s.extraButtons}>{extraButtons}</div>}
    </div>
  );
};
