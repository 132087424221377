import React, { FC, useCallback, useState } from 'react';
import { useStore } from 'effector-react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import s from './NewTeacherForm.module.scss';
import { Button, Form, FormItem, Input, Select, ImageInput } from '../../ui';
import { $selectDisciplines, getTeachers } from '../../store';
import {
  EMAIL_EXAMPLE,
  EMAIL_PATTERN,
  REQUIRED_FIELD,
  TEACHERS,
} from '../../constants';
import {
  clearPhoneNumber,
  formatStringToPhoneNumber,
  request,
} from '../../libs';

export const validationSchema = yup.object({
  lastName: yup.string().required(REQUIRED_FIELD),
  firstName: yup.string().required(REQUIRED_FIELD),
  middleName: yup.string().required(REQUIRED_FIELD),
  discipline: yup.string().required(REQUIRED_FIELD),
  phone: yup.string().required(REQUIRED_FIELD),
  email: yup.string().required(REQUIRED_FIELD),
  images: yup.array(),
});

export type TFormData = {
  lastName: string;
  firstName: string;
  middleName: string;
  discipline: string;
  images: any[];
  phone: string;
  email: string;
  comment?: string;
};

interface Props {
  onSuccess?: (...args: any[]) => void;
}

export const NewTeacherForm: FC<Props> = ({ onSuccess }) => {
  const disciplines = useStore($selectDisciplines);
  const [blocking, setBlocking] = useState(false);

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        lastName: '',
        firstName: '',
        middleName: '',
        discipline: `${disciplines?.[0]?.value}`,
        images: [],
        phone: '',
        email: '',
      },
      onSubmit,
      validationSchema,
    });

  async function onSubmit({
    images,
    phone,
    discipline,
    ...formData
  }: TFormData): Promise<void> {
    try {
      setBlocking(true);

      const data = new FormData();
      const keys = Object.keys(formData);

      // @ts-ignore
      keys.forEach((key) => data.append(key, formData[key]));
      data.append('phone', clearPhoneNumber(phone));
      data.append('photo', images[0].file);
      data.append('disciplineId', discipline);

      await request({
        url: TEACHERS,
        options: {
          method: 'POST',
          body: data,
        },
        successNotify: 'Форма отправлена успешно',
      })();

      getTeachers();

      onSuccess?.();
    } catch ({ message }) {
      console.error(message);
    } finally {
      setBlocking(false);
    }
  }

  const handleImageChange = useCallback((imageList: any[]): void => {
    setFieldValue('images', imageList);
  }, []);

  return (
    <Form blocking={blocking} className={s.form} onSubmit={handleSubmit}>
      <FormItem
        label="Фамилия"
        error={!!errors.lastName && touched.lastName}
        helperText={!!errors.lastName && touched.lastName && errors.lastName}
      >
        <Input
          value={values.lastName}
          name="lastName"
          error={!!errors.lastName && touched.lastName}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Имя"
        error={!!errors.firstName && touched.firstName}
        helperText={!!errors.firstName && touched.firstName && errors.firstName}
      >
        <Input
          value={values.firstName}
          name="firstName"
          error={!!errors.firstName && touched.firstName}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Отчество"
        error={!!errors.middleName && touched.middleName}
        helperText={
          !!errors.middleName && touched.middleName && errors.middleName
        }
      >
        <Input
          value={values.middleName}
          name="middleName"
          error={!!errors.middleName && touched.middleName}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Фото"
        error={!!errors.images && (touched.images as any)}
        helperText={
          !!errors.images && touched.images && (errors.images as string)
        }
      >
        <ImageInput
          error={!!errors.images && (touched.images as any)}
          images={values.images}
          setImages={handleImageChange}
        />
      </FormItem>
      <FormItem
        label="Дисциплина"
        error={!!errors.discipline && touched.discipline}
        helperText={
          !!errors.discipline && touched.discipline && errors.discipline
        }
      >
        <Select
          value={values.discipline}
          name="discipline"
          onChange={handleChange}
          options={disciplines}
        />
      </FormItem>
      <FormItem
        label="Телефон"
        error={!!errors.phone && touched.phone}
        helperText={!!errors.phone && touched.phone && errors.phone}
      >
        <Input
          value={formatStringToPhoneNumber(values.phone) as string}
          name="phone"
          error={!!errors.phone && touched.phone}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Электронная почта"
        error={!!errors.email && touched.email}
        helperText={!!errors.email && touched.email && errors.email}
      >
        <Input
          value={values.email}
          name="email"
          error={!!errors.email && touched.email}
          onChange={handleChange}
          pattern={EMAIL_PATTERN}
          title={EMAIL_EXAMPLE}
        />
      </FormItem>
      {/* <FormItem */}
      {/*  label="Комментарий" */}
      {/*  error={!!errors.comment && touched.comment} */}
      {/*  helperText={!!errors.comment && touched.comment && errors.comment} */}
      {/* > */}
      {/*  <Textarea */}
      {/*    value={values.comment} */}
      {/*    name="comment" */}
      {/*    error={!!errors.comment && touched.comment} */}
      {/*    onChange={handleChange} */}
      {/*  /> */}
      {/* </FormItem> */}
      <Button type="submit" fullWidth>
        Добавить преподавателя
      </Button>
    </Form>
  );
};
