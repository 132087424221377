import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Container } from '../container';

import s from './WithSidebar.module.scss';

interface IWithSidebar {
  sidebar: ReactNode;
  withContainer?: boolean;
  className?: string;
}

export const WithSidebar: FC<IWithSidebar> = ({
  children,
  sidebar,
  withContainer,
  className,
}) => {
  if (withContainer) {
    return (
      <Container>
        <div className={cn(s.root, className)}>
          <div className={s.content}>{children}</div>
          <div className={s.sidebar}>{sidebar}</div>
        </div>
      </Container>
    );
  }

  return (
    <div className={cn(s.root, className)}>
      <div className={s.content}>{children}</div>
      <div className={s.sidebar}>{sidebar}</div>
    </div>
  );
};
