import React, { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as Camera } from '../../../assets/images/Camera.svg';
import s from './Button.module.scss';

interface Props {
  onClick?: (...args: any[]) => void;
  className?: string;
}

export const CameraButton: FC<Props> = ({ onClick, className }) => {
  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      className={cn(s.camera, className)}
    >
      <Camera />
    </div>
  );
};
