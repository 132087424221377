import React, { FC } from 'react';

import { Typography } from '../../atoms/Typography';

import s from './TimeSquare.module.scss';

export const TimeSquare: FC = ({ children }) => {
  return (
    <div className={s.root}>
      <Typography variant="body2" bold>
        {children}
      </Typography>
    </div>
  );
};
