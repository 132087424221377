/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import cn from 'classnames';

import s from './RoundButton.module.scss';

interface ButtonProps {
  onClick?: (...args: any[]) => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  active?: boolean;
  uppercase?: boolean;
  [x: string]: any;
}

export const RoundButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  type = 'button',
  active,
  fullWidth,
  uppercase,
  ...rest
}) => {
  const buttonClassName = cn(s.button, {
    [s.button_active]: active,
    [s.fullWidth]: fullWidth,
    [s.uppercase]: uppercase,
    [className as string]: className,
  });

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      tabIndex={0}
      type={type}
      {...rest}
    >
      <span tabIndex={-1}>{children}</span>
    </button>
  );
};
