import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import s from './SectionCalendar.module.scss';
import { SectionCalendarHeader } from './Header';
import { Calendar } from './Calendar';
import { Button } from '../../ui';
import { Modal, request, useModal } from '../../libs';
import { NewEntryForm } from './NewEntryForm';
import { ISection, ISectionGroup } from '../../types';
import { SECTION_GROUPS } from '../../constants';

export const SectionCalendar: FC<ISection> = ({ name, sectionGroups }) => {
  const [children, setChildren] = useState([]);
  const { isActive, toggleModal } = useModal();
  const GROUPS_OPTIONS = useMemo(
    () => [
      ...new Set(
        sectionGroups?.map(({ name: text, id: value }) => ({
          text,
          value,
        }))
      ),
    ],
    [sectionGroups]
  );
  const [activeGroup, setActiveGroup] = useState(GROUPS_OPTIONS?.[0]?.value);
  const TIMES_OPTIONS = useMemo(
    () =>
      [
        ...new Set(
          sectionGroups
            ?.map(({ sectionGroupSchedules }) =>
              sectionGroupSchedules
                ?.filter(({ sectionGroupId }) => sectionGroupId === activeGroup)
                ?.map(({ sectionGroupScheduleTimes }) =>
                  sectionGroupScheduleTimes?.map(({ startTime }) => startTime)
                )
                .flat()
            )
            .flat()
        ),
      ]
        .map((text, index) => ({
          value: index,
          text: text || '',
        }))
        .sort((a, b) => {
          if (a.text.length > 0 && b.text.length > 0) {
            return (
              // @ts-ignore
              new Date(`1970/01/01 ${a.text}`) -
              // @ts-ignore
              new Date(`1970/01/01 ${b.text}`)
            );
          }

          return 1;
        }),
    [sectionGroups, activeGroup]
  );
  const [activeTime, setActiveTime] = useState(TIMES_OPTIONS?.[0]?.value);

  const onActiveGroupChange = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>): void => {
    setActiveGroup(Number(value));
  };

  const onActiveTimeChange = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>): void => {
    setActiveTime(Number(value));
  };

  const filteredSectionGroups = useMemo(
    () =>
      sectionGroups
        ?.map(({ sectionGroupSchedules, ...schedules }) => {
          return {
            ...schedules,
            sectionGroupSchedules: sectionGroupSchedules
              ?.filter(({ sectionGroupId }) => sectionGroupId === activeGroup)
              ?.map(({ sectionGroupScheduleTimes, ...times }) => {
                return {
                  ...times,
                  sectionGroupScheduleTimes: sectionGroupScheduleTimes?.filter(
                    ({ startTime }) => {
                      const activeTimeObj = TIMES_OPTIONS?.find(
                        ({ value }) => value === activeTime
                      );

                      return startTime === activeTimeObj?.text;
                    }
                  ),
                };
              })
              ?.filter(
                ({ sectionGroupScheduleTimes }) =>
                  sectionGroupScheduleTimes?.length
              ),
          };
        })
        ?.filter(
          ({ sectionGroupSchedules }) => sectionGroupSchedules?.length
        ) ?? [],
    [activeTime, sectionGroups, activeGroup]
  );

  const sectionGroupsIds = useMemo(
    () => filteredSectionGroups?.map(({ id }) => id),
    [filteredSectionGroups]
  );

  const getSectionGroupChildren = async (): Promise<void> => {
    try {
      const req = sectionGroupsIds?.map((id) =>
        request({
          url: `${SECTION_GROUPS}/${id}/children`,
        })()
      );

      if (req) {
        const data = await Promise.all(req);

        setChildren(data?.[0] as any);
      }
    } catch ({ message }) {
      console.error(message);
    }
  };

  useEffect(() => {
    getSectionGroupChildren();
  }, [sectionGroupsIds]);

  return (
    <div className={s.wrap}>
      <SectionCalendarHeader
        name={name}
        activeTime={`${activeTime}`}
        activeGroup={`${activeGroup}`}
        onTimeChange={onActiveTimeChange}
        onActiveGroupChange={onActiveGroupChange}
        groupsOptions={GROUPS_OPTIONS}
        timesOptions={TIMES_OPTIONS}
      />
      <Calendar
        name={name}
        sectionGroups={filteredSectionGroups as ISectionGroup[]}
        sectionGroupChildren={children}
        getSectionGroupChildren={getSectionGroupChildren}
      />
      <Button onClick={toggleModal} className={s.add_note}>
        Добавить запись
      </Button>
      <Modal title="Новая запись" isActive={isActive} toggleModal={toggleModal}>
        <NewEntryForm
          activeGroup={activeGroup as number}
          groupsOptions={GROUPS_OPTIONS}
          sectionGroups={sectionGroups as ISectionGroup[]}
          getSectionGroupChildren={getSectionGroupChildren}
          toggleModal={toggleModal}
        />
      </Modal>
    </div>
  );
};
