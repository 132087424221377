export const RECORD_TYPES = ['Индивидуальная', 'Групповая'];
export const PEREODICITY_TYPES = ['Месяц', 'Неделя', 'Год'];
export const WEEK_DAYS_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const WEEK_DAYS_LONG = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

export const GENDER_OPTIONS = [
  {
    value: 0,
    text: 'Муж',
  },
  {
    value: 1,
    text: 'Жен',
  },
];
