import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Route, Switch } from 'react-router-dom';
import { Header } from '../../components';
import { EmptyPage, MainTemplate, Spinner } from '../../ui';
import { $organization, getOrganizationFx } from '../../store';
import { Preview } from './Preview';
import { EditPage } from './EditPage';

export const ContactsPage: FC = () => {
  const organization = useStore($organization);
  const loading = useStore(getOrganizationFx.pending);

  return (
    <MainTemplate withSliderTabs header={<Header />}>
      {loading && <Spinner />}
      {!loading && organization && (
        <Switch>
          <Route path="/contacts" exact>
            <Preview />
          </Route>
          <Route path="/contacts/edit">
            <EditPage />
          </Route>
        </Switch>
      )}
      {!loading && !organization && (
        <EmptyPage
          title="У вас нет организации"
          subtitle="Данные отсутвуют. Возможно, произошла ошибка"
        />
      )}
    </MainTemplate>
  );
};
