import React, { FC } from 'react';
import s from './SectionCalendar.module.scss';
import { Avatar, Button, FormItem, Input, Typography } from '../../ui';
import { ISectionGroupChildren } from '../../types';
import { request } from '../../libs';
import { SECTION_GROUP_CHILDREN } from '../../constants';

interface Props {
  name: string;
  sectionGroupChildren: ISectionGroupChildren[];
  getSectionGroupChildren: () => void;
  toggleModal: () => void;
}

export const EntryList: FC<Props> = ({
  name,
  sectionGroupChildren,
  getSectionGroupChildren,
  toggleModal,
}) => {
  const deleteChild = async (id: number): Promise<void> => {
    try {
      await request({
        url: `${SECTION_GROUP_CHILDREN}/${id}`,
        options: {
          method: 'DELETE',
        },
        successNotify: 'Удаление прошло успешно',
      })();

      getSectionGroupChildren();
      if (sectionGroupChildren.length < 2) {
        toggleModal();
      }
    } catch ({ message }) {
      console.error(message);
    }
  };

  return (
    <div className={s.list}>
      <div className={s.list_header}>
        <FormItem label="Наименование услуги">
          <Input value={name} disabled />
        </FormItem>
      </div>
      <div className={s.list_wrap}>
        {sectionGroupChildren.map(
          ({ id, child: { firstName, lastName, middleName } }) => (
            <div className={s.list_item} key={id}>
              <Avatar variant="M" />
              <Typography className={s.list_item_name} bold>
                {lastName && firstName && middleName
                  ? `${lastName} ${firstName} ${middleName}`
                  : 'Низвестный'}
              </Typography>
              <Button variant="danger" onClick={() => deleteChild(id)}>
                Удалить
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  );
};
