import { useStore } from 'effector-react';
import { $auth, login as storeLogin, logout as storeLogout } from '../../store';
import { IAuth, IAuthFormatted } from '../../types';
import { LocalStorageHelper } from '../localStorage';

const ls = new LocalStorageHelper('auth');

interface IUseAuth extends IAuthFormatted {
  login: (authObj: IAuth) => void;
  logout: () => void;
}

export function useAuth(): IUseAuth {
  const { jwt, isAuthorized, username } = useStore($auth);

  const login = ({ access_token, username: _username }: IAuth): void => {
    ls.set('isAuthorized', true);
    ls.set('jwt', access_token);
    ls.set('username', _username);
    storeLogin({ access_token, username: _username });
  };

  const logout = (): void => {
    ls.remove('jwt');
    ls.set('isAuthorized', false);
    ls.remove('username');
    storeLogout();
  };

  return {
    isAuthorized,
    username,
    jwt,
    login,
    logout,
  };
}
