import React, { FC, useMemo, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import cn from 'classnames';
import { addMonths, isSameDay, isSameMonth, subMonths } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import s from './SectionCalendar.module.scss';
import { Color, IconButton, Tag, Typography } from '../../ui';
import { declensionOfNumber, formatRu, Modal, useModal } from '../../libs';
import { childDeclension, createMonthCells } from './helpers';
import { WEEK_DAYS_SHORT } from '../../constants';
import { EntryList } from './EntryList';
import { ISectionGroup, ISectionGroupChildren } from '../../types';

interface Props {
  name: string;
  sectionGroups: ISectionGroup[];
  sectionGroupChildren: ISectionGroupChildren[];
  getSectionGroupChildren: () => void;
}

export const Calendar: FC<Props> = ({
  name,
  sectionGroups,
  sectionGroupChildren,
  getSectionGroupChildren,
}) => {
  const { isActive, toggleModal } = useModal();
  const [date, setDate] = useState(new Date());
  const [activeEntryDay, setActiveEntryDay] = useState(new Date());
  const children = sectionGroupChildren?.map(({ child }) => child) ?? [];

  const monthCells = useMemo(() => {
    return createMonthCells(date);
  }, [date]);

  const prevMonth = (): void => setDate((prevState) => subMonths(prevState, 1));
  const nextMonth = (): void => setDate((prevState) => addMonths(prevState, 1));

  const getDayColor = (day: Date): Color => {
    if (!isSameMonth(new Date(day), date)) {
      return 'secondary';
    }

    if (isSameDay(day, new Date())) {
      return 'white';
    }

    return 'primary';
  };

  const onEntryPreviewClick = (event: any, day: Date): void => {
    setActiveEntryDay(day);
    event.stopPropagation();
    toggleModal();
  };

  const activeWeekDays = [
    ...new Set(
      sectionGroups.map(({ sectionGroupSchedules }) =>
        sectionGroupSchedules.map(({ dayOfWeek }) => dayOfWeek)
      )
    ),
  ].flat();

  return (
    <div className={s.root}>
      <div className={s.controls}>
        <Typography capitalize bold>
          {formatRu(date, 'LLLL, yyyy')}
        </Typography>
        <div className={s.controls_buttons}>
          <Tooltip title={formatRu(subMonths(date, 1), 'LLLL, yyyy')}>
            <div>
              <IconButton onClick={prevMonth} type="arrowLeft" />
            </div>
          </Tooltip>
          <Tooltip title={formatRu(addMonths(date, 1), 'LLLL, yyyy')}>
            <div>
              <IconButton onClick={nextMonth} type="arrowRight" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={s.cell_wrap}>
        {monthCells.map((array, i) => (
          <div className={s.cell_row} key={uniqueId()}>
            {array.map((day, j) => (
              <div
                className={cn(s.cell, {
                  [s.cell_filled]: !activeWeekDays?.includes(j),
                })}
                key={day.toLocaleDateString()}
              >
                <div className={s.cell_header}>
                  <Typography
                    className={cn(s.cell_number, {
                      [s.cell_number_today]: isSameDay(day, new Date()),
                    })}
                    color={getDayColor(day)}
                  >
                    {formatRu(day, 'd')}
                  </Typography>
                  {i === 0 && (
                    <Typography
                      className={s.cell_weekday}
                      variant="h5"
                      color="secondary"
                    >
                      {WEEK_DAYS_SHORT[j]}
                    </Typography>
                  )}
                </div>
                {activeWeekDays?.includes(j) && children.length > 0 && (
                  <>
                    <div
                      className={s.cell_countButton}
                      role="button"
                      tabIndex={0}
                      onClick={(event) => onEntryPreviewClick(event, day)}
                    >
                      {`${children.length} ${declensionOfNumber(
                        children.length,
                        childDeclension
                      )}`}
                    </div>
                    <Tag className={s.cell_tag}>{name}</Tag>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <Modal
        title={`Записи на ${formatRu(activeEntryDay, 'PPP')}`}
        isActive={isActive}
        toggleModal={toggleModal}
      >
        <EntryList
          sectionGroupChildren={sectionGroupChildren}
          name={name}
          getSectionGroupChildren={getSectionGroupChildren}
          toggleModal={toggleModal}
        />
      </Modal>
    </div>
  );
};
