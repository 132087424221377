export class LocalStorageHelper {
  prefix = '';

  constructor(prefix = '') {
    this.prefix = `${prefix}@`;
  }

  set<T>(key: string, value: T): void {
    window.localStorage?.setItem(this.prefix + key, JSON.stringify(value));
  }

  get<T>(key: string): T | null {
    const data = window.localStorage?.getItem(this.prefix + key);

    if (data) {
      return <T>JSON.parse(data);
    }

    return null;
  }

  remove(key: string): void {
    window.localStorage?.removeItem(this.prefix + key);
  }

  // eslint-disable-next-line class-methods-use-this
  clear(): void {
    window.localStorage?.clear();
  }
}
