import React, { FC } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { IconButton } from '../../atoms';

import s from './FormControls.module.scss';

interface IFormControls {
  onPlusClick?: (...args: any[]) => void;
  onCloseClick?: (...args: any[]) => void;
  plusTitle?: string;
  closeTitle?: string;
}

export const FormControls: FC<IFormControls> = ({
  onPlusClick,
  onCloseClick,
  plusTitle,
  closeTitle,
}) => {
  return (
    <div className={s.root}>
      {plusTitle && onPlusClick && (
        <Tooltip title={plusTitle} arrow>
          <div
            className={s.control}
            tabIndex={0}
            role="button"
            onClick={onPlusClick}
          >
            <IconButton type="plus" />
          </div>
        </Tooltip>
      )}
      {onCloseClick && closeTitle && (
        <Tooltip title={closeTitle} arrow>
          <div
            className={s.control}
            tabIndex={0}
            role="button"
            onClick={onCloseClick}
          >
            <IconButton type="close" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
