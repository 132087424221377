import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import s from './SectionInfo.module.scss';
import { Typography } from '../../atoms';
import { GroupList } from '../../molecules';
import { ISection, ISectionGroup } from '../../../types';
import { PEREODICITY_TYPES, RECORD_TYPES } from '../../../constants';

interface Props extends Partial<ISection> {
  className?: string;
}

export const SectionInfo: FC<Props> = ({
  className,
  name,
  id,
  direction,
  discipline,
  recordType,
  cost,
  costDuration,
  sectionGroups,
}) => {
  return (
    <div className={cn(s.wrap, className)}>
      {name && (
        <Link to={`/section/${id}`}>
          <Typography className={s.name} variant="subheading1" color="link">
            {name}
          </Typography>
        </Link>
      )}
      <div className={s.info}>
        {direction && discipline && (
          <Typography variant="subheading2" color="secondary">
            {`${direction.name}, ${discipline.name}`}
          </Typography>
        )}
        {recordType !== undefined && (
          <Typography variant="h5" color="secondary">
            {RECORD_TYPES[recordType]}
          </Typography>
        )}
        {cost !== undefined && costDuration !== undefined && (
          <Typography variant="subheading2">{`Стоимость – ${cost} ₽ / ${PEREODICITY_TYPES[costDuration]}`}</Typography>
        )}
      </div>
      <GroupList groups={sectionGroups as ISectionGroup[]} />
    </div>
  );
};
