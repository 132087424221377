import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import s from './Teacher.module.scss';
import { ITeacher } from '../../types';
import { Avatar, Link, Typography } from '../../ui';
import { createFio, formatStringToPhoneLink } from '../../libs';

export const Teacher: FC<ITeacher> = ({
  id,
  phone,
  email,
  photo,
  firstName,
  lastName,
  middleName,
}) => {
  return (
    <div className={s.root}>
      <Avatar
        src={photo?.url}
        variant="L"
        alt={`${lastName} ${firstName} ${middleName}`}
      />
      <div className={s.content}>
        <RouterLink to={`/teachers/${id}`}>
          <Typography className={s.name} variant="h2">
            {createFio(lastName, firstName, middleName)}
          </Typography>
        </RouterLink>
        {phone && (
          <Link className={s.phone} href={`tel:${phone}`}>
            {formatStringToPhoneLink(phone)}
          </Link>
        )}
        {email && (
          <Link color="link" href={`mailto:${email}`}>
            {email}
          </Link>
        )}
      </div>
    </div>
  );
};
