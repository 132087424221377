import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatRu = (
  date: Date | number,
  formatStr = 'dd LLLL',
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
): string => {
  return format(new Date(date), formatStr, {
    ...options,
    locale: ru,
  });
};
