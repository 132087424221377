import React, { FC, useMemo, useState } from 'react';
import { useParams, useHistory, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from 'effector-react';
import { Header, RouterSlilderTabs, SectionCalendar } from '../../components';
import {
  BackwardButton,
  Button,
  Form,
  MainTemplate,
  Spinner,
  Typography,
  WithSidebar,
} from '../../ui';
import { Section } from './Section';
import s from './Section.module.scss';
import { Modal, request, useModal } from '../../libs';
import { SECTIONS } from '../../constants';
import { $sections, getSectionsFx } from '../../store/sections';
import { ISection } from '../../types';

export const SectionPage: FC = () => {
  const sections = useStore($sections);
  const loading = useStore(getSectionsFx.pending);
  const [blocking, setBlocking] = useState(false);
  const { id } = useParams<any>();
  const history = useHistory();
  const { isActive, toggleModal } = useModal();
  const currentSection = sections?.find(
    ({ id: sectionId }) => sectionId === Number(id)
  );

  const LINKS = useMemo(
    () => [
      {
        to: `/section/${id}`,
        title: 'Расписание',
      },
      {
        to: `/section/${id}/note`,
        title: 'Записи',
      },
    ],
    []
  );

  const deleteService = async (): Promise<void> => {
    try {
      setBlocking(true);
      await request({
        url: `${SECTIONS}/${id}`,
        options: {
          method: 'DELETE',
        },
      })();

      await getSectionsFx();

      toast.success('Услуга успешно удалена');
      setBlocking(false);
      history.push('/');
    } catch ({ message }) {
      console.error(message);
      setBlocking(false);
    }
  };

  return (
    <MainTemplate header={<Header />}>
      {loading && <Spinner />}
      {!loading && currentSection && (
        <>
          <div className={s.header}>
            <BackwardButton className={s.backward} />
            <Typography variant="h2">{currentSection?.name}</Typography>
          </div>
          <RouterSlilderTabs links={LINKS} />
          <Switch>
            <Route path="/section/:id/note">
              <SectionCalendar {...(currentSection as ISection)} />
            </Route>
            <Route path="/section/:id">
              <WithSidebar
                sidebar={
                  <div className={s.sidebar}>
                    <Button
                      disabled={blocking}
                      onClick={toggleModal}
                      fullWidth
                      variant="error"
                    >
                      Удалить услугу
                    </Button>
                  </div>
                }
                className={s.teachers_wrap}
              >
                <Section
                  blocking={blocking}
                  setBlocking={setBlocking}
                  {...(currentSection as ISection)}
                />
              </WithSidebar>
            </Route>
          </Switch>
          <Modal
            title="Удалить услугу"
            isActive={isActive}
            toggleModal={toggleModal}
          >
            <Form blocking={blocking} className={s.delete}>
              <Typography>
                {`Вы уверены, что хотите удалить услугу “${currentSection?.name}”?`}
              </Typography>
              <Button
                variant="error"
                onClick={deleteService}
                className={s.delete_button}
                fullWidth
              >
                Да, удалить
              </Button>
              <Button onClick={toggleModal} variant="outline" fullWidth>
                Я передумал
              </Button>
            </Form>
          </Modal>
        </>
      )}
    </MainTemplate>
  );
};
