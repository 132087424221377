import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import { BackwardButton } from '../../ui';
import { $teachers, getData } from '../../store';
import { TeacherEditForm } from './Form';
import s from './TeacherPage.module.scss';

export const TeacherPage: FC = () => {
  const params = useParams<{ id: string }>();
  const teachers = useStore($teachers);
  const currentTeacher = teachers.find(({ id }) => id === Number(params.id));

  return (
    <>
      <div className={s.header}>
        <BackwardButton className={s.backward} />
      </div>
      {currentTeacher && <TeacherEditForm teacher={currentTeacher} />}
    </>
  );
};
