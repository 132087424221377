import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';

import 'normalize.css';
import './assets/styles/globalStyles.scss';

const renderTarget = document.getElementById('app');
const app = (
  <Router>
    <App />
  </Router>
);

render(app, renderTarget);
