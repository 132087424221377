import { createEffect, createEvent, createStore, forward } from 'effector';
import { DIRECTIONS } from '../constants';
import { storeRequest } from '../libs';
import { IDirection } from '../types';

export const $directions = createStore<IDirection[]>([]);

export const $selectDirections = $directions.map((array) =>
  array.map(({ id, name }) => ({
    text: name,
    value: id,
  }))
);

export const getDirections = createEvent();

export const getDirectionsFx = createEffect(async () => {
  const res = await storeRequest(DIRECTIONS);

  return res;
});

forward({
  from: getDirections,
  to: getDirectionsFx,
});

$directions.on(getDirectionsFx.doneData, (_, { data }) => data);
