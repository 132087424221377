import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from './Button';

interface IBackwardButton {
  className?: string;
}

export const BackwardButton: FC<IBackwardButton> = ({
  className,
  children = 'Вернуться назад',
}) => {
  const history = useHistory();

  return (
    <Button
      variant="link"
      className={className}
      onClick={() => history.goBack()}
    >
      {children}
    </Button>
  );
};
