import React, { ChangeEvent, memo } from 'react';
import { ISectionGroupSchedule } from '../../types';
import s from './SectionCalendar.module.scss';
import { FormItem, Input, ISelectOption, Select } from '../../ui';

interface Props {
  name: string;
  schedules?: ISectionGroupSchedule;
  activeTime: string;
  activeGroup: string;
  onTimeChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onActiveGroupChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  timesOptions: ISelectOption[];
  groupsOptions: ISelectOption[];
}

export const SectionCalendarHeader = memo<Props>(
  ({
    name,
    activeGroup,
    groupsOptions,
    activeTime,
    onTimeChange,
    timesOptions,
    onActiveGroupChange,
  }) => {
    return (
      <div className={s.header}>
        <FormItem fullWidth label="Название услуги">
          <Input fullWidth value={name} disabled onChange={() => undefined} />
        </FormItem>
        <FormItem fullWidth label="Группа">
          <Select
            fullWidth
            value={activeGroup}
            options={groupsOptions}
            onChange={onActiveGroupChange}
          />
        </FormItem>
        <FormItem fullWidth label="Время">
          <Select
            fullWidth
            value={activeTime}
            options={timesOptions}
            onChange={onTimeChange}
          />
        </FormItem>
      </div>
    );
  }
);
