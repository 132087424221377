import { createEvent, createStore } from 'effector';
import { IAuth, IAuthFormatted } from '../types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { LocalStorageHelper } = require('../libs/localStorage');

const ls = new LocalStorageHelper('auth');
const jwt = ls.get('jwt');
const isAuthorized = ls.get('isAuthorized');
const username = ls.get('username');

const defaultState = {
  jwt,
  isAuthorized: isAuthorized ?? false,
  username,
};

export const $auth = createStore<IAuthFormatted>(defaultState);

export const login = createEvent<IAuth>();
export const logout = createEvent();

$auth
  .on(login, (_, { access_token, username: _username }) => ({
    jwt: access_token,
    isAuthorized: true,
    username: _username,
  }))
  .reset(logout);
