import React, { FC, useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { $organization, $selectCities, getOrganization } from '../../store';
import s from './Contacts.module.scss';
import {
  Button,
  ChangebleOrganizationHeader,
  Checkbox,
  Form,
  FormItem,
  Input,
  Select,
  Typography,
} from '../../ui';
import {
  EMAIL_ERROR_FORMAT,
  ORGANIZATION,
  REQUIRED_FIELD,
} from '../../constants';
import {
  clearPhoneNumber,
  formatStringToPhoneNumber,
  request,
} from '../../libs';

type FormData = {
  name: string;
  email: string;
  phone: string;
  city: string;
  address: string;
  images: any[];
  isRegisterByPhoneOnly: boolean;
};

const validationSchema = yup.object().shape({
  email: yup.string().email(EMAIL_ERROR_FORMAT),
  name: yup.string(),
  phone: yup.string().required(REQUIRED_FIELD),
  city: yup.string().required(REQUIRED_FIELD),
  address: yup.string().required(REQUIRED_FIELD),
  images: yup.array().of(yup.mixed()),
});

const DEFAULT_LATITUDE = 55.79639;
const DEFAULT_LONGITUDE = 49.10889;

export const EditPage: FC = () => {
  const organization = useStore($organization);
  const cities = useStore($selectCities);
  const [blocking, setBlocking] = useState(false);
  const [latitude, setLatitude] = useState(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState(DEFAULT_LONGITUDE);

  useEffect(() => {
    if (organization?.latitude && organization.longitude) {
      setLatitude(Number(organization.latitude));
      setLongitude(Number(organization.longitude));
    }
  }, [organization]);

  const { values, setFieldValue, errors, touched, handleSubmit, handleChange } =
    useFormik({
      onSubmit,
      initialValues: {
        email: organization?.email ?? '',
        name: organization?.name ?? '',
        phone: organization?.phone ?? '',
        isRegisterByPhoneOnly: organization?.isRegisterByPhoneOnly ?? false,
        city: `${organization?.city?.id}` ?? `${cities?.[0]?.value}`,
        address: organization?.address ?? '',
        images: [{ dataUrl: organization?.image?.url }],
      },
      validationSchema,
    });

  async function onSubmit({
    phone,
    city,
    images,
    ...formData
  }: FormData): Promise<void> {
    try {
      setBlocking(true);

      const data = {
        ...formData,
        image: images?.[0]?.file,
        phone: clearPhoneNumber(phone),
        city: Number(city),
        latitude: latitude.toFixed(5) || String(DEFAULT_LATITUDE),
        longitude: longitude.toFixed(5) || String(DEFAULT_LONGITUDE),
      };

      const body = new FormData();

      // @ts-ignore
      Object.entries(data).forEach(([key, value]) => body.append(key, value));

      await request({
        url: `${ORGANIZATION}/${organization?.id}`,
        options: {
          method: 'PUT',
          body,
        },
        delay: 1000,
      })();

      getOrganization();
    } catch ({ message }) {
      console.error(message);
    } finally {
      setBlocking(false);
    }
  }

  const onMapClick = (event: any): void => {
    const [newLatitude, newLongitude] = event?.get('coords');

    if (newLatitude && newLongitude) {
      setLatitude(newLatitude);
      setLongitude(newLongitude);
    }
  };

  const handleImageChange = useCallback((imageList: any[]): void => {
    setFieldValue('images', imageList);
  }, []);

  return (
    <Form blocking={blocking} onSubmit={handleSubmit} className={s.edit}>
      {organization && (
        <ChangebleOrganizationHeader
          images={values.images}
          onChange={handleImageChange}
          {...organization}
        />
      )}
      <Typography variant="body1" color="secondary" bold>
        Контакты
      </Typography>
      <div className={s.edit_fields}>
        <FormItem
          label="Название"
          error={!!errors.name && touched.name}
          helperText={!!errors.name && touched.name && errors.name}
        >
          <Input
            name="name"
            error={!!errors.name && touched.name}
            value={values.name}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem
          label="Телефон для связи"
          error={!!errors.phone && touched.phone}
          helperText={!!errors.phone && touched.phone && errors.phone}
        >
          <Input
            name="phone"
            error={!!errors.phone && touched.phone}
            value={formatStringToPhoneNumber(values.phone) as string}
            onChange={handleChange}
            maxLength={18}
          />
        </FormItem>
        <Checkbox
          className={s.checkbox}
          label="Запись по звонку"
          name="isRegisterByPhoneOnly"
          checked={values.isRegisterByPhoneOnly}
          onChange={handleChange}
        />
        <FormItem
          label="Электронная почта"
          error={!!errors.email && touched.email}
          helperText={!!errors.email && touched.email && errors.email}
        >
          <Input
            value={values.email}
            name="email"
            error={!!errors.email && touched.email}
            onChange={handleChange}
          />
        </FormItem>
        <div className={s.edit_address}>
          <FormItem
            label="Адрес"
            error={!!errors.city && touched.city}
            helperText={!!errors.city && touched.city && errors.city}
          >
            <Select
              name="city"
              options={cities}
              value={values.city}
              error={!!errors.city && touched.city}
              onChange={handleChange}
            />
          </FormItem>
          <FormItem
            error={!!errors.address && touched.address}
            helperText={!!errors.address && touched.address && errors.address}
          >
            <Input
              name="address"
              value={values.address}
              error={!!errors.address && touched.address}
              onChange={handleChange}
            />
          </FormItem>
        </div>
      </div>
      {organization?.latitude && organization?.longitude && (
        <div className={cn(s.map, s.edit_map)}>
          <YMaps>
            <Map
              width="100%"
              height="100%"
              defaultState={{
                center: [latitude, longitude],
                controls: [],
                zoom: 17,
              }}
              onClick={onMapClick}
            >
              <ZoomControl options={{ float: 'right' }} />
              <Placemark
                geometry={[latitude, longitude]}
                modules={['geoObject.addon.balloon']}
              />
            </Map>
          </YMaps>
        </div>
      )}
      <Button type="submit" className={s.edit_submit}>
        Сохранить изменения
      </Button>
    </Form>
  );
};
