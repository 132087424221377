import React, { FC } from 'react';
import cn from 'classnames';
import s from './Form.module.scss';

interface Props {
  blocking?: boolean;
  className?: string;
  [x: string]: any;
}

export const Form: FC<Props> = ({
  blocking = false,
  children,
  className,
  ...rest
}) => {
  return (
    <form className={cn(s.root, className)} {...rest}>
      {blocking && (
        <div className={s.overlay}>
          <div />
        </div>
      )}
      {children}
    </form>
  );
};
