export const HOST = 'https://lk.edu.obrkarta.ru';
// export const HOST = 'http://0.0.0.0:8080';
export const LOGIN = '/api/v1/Account/token';
export const ORGANIZATION = '/api/v1/Organizations';
export const DIRECTIONS = '/api/v1/Directions';
export const DISCIPLINES = '/api/v1/Disciplines';
export const TEACHERS = '/api/v1/Teachers';
export const SECTIONS = '/api/v1/Sections';
export const SECTION_GROUPS = '/api/v1/Sections/sectionGroup';
export const SECTION_GROUP_CHILDREN = '/api/v1/Sections/sectionGroup/children';
export const CITIES = '/api/v1/Cities';

export const API_VERSION = 1;
