import { createEffect, createEvent, createStore, forward } from 'effector';
import { DISCIPLINES } from '../constants';
import { storeRequest } from '../libs';
import { IDiscipline } from '../types';

export const $disciplines = createStore<IDiscipline[]>([]);

export const $selectDisciplines = $disciplines.map((array) =>
  array.map(({ id, name }) => ({
    text: name,
    value: id,
  }))
);

export const getDisciplines = createEvent();

export const getDisciplinesFx = createEffect(async () => {
  const res = await storeRequest(DISCIPLINES);

  return res;
});

forward({
  from: getDisciplines,
  to: getDisciplinesFx,
});

$disciplines.on(getDisciplinesFx.doneData, (_, { data }) => data);
