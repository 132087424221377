import React, { FC } from 'react';

import cn from 'classnames';
import { Input, Typography } from '../../atoms';

import s from './IntervalInputs.module.scss';

interface IIntervalInputs {
  firstLabel?: string;
  secondLabel?: string;
  firstName: string;
  secondName: string;
  onChange?: (...args: any[]) => void;
  onFirstChange?: (...args: any[]) => void;
  onSecondChange?: (...args: any[]) => void;
  values: Record<string, any>;
}

export const IntervalInputs: FC<IIntervalInputs> = ({
  firstLabel = 'Минимум',
  secondLabel = 'Максимум',
  firstName,
  secondName,
  values,
  onChange,
  onFirstChange,
  onSecondChange,
}) => {
  return (
    <div className={s.wrap}>
      <div className={s.item}>
        <Typography className={s.label} color="secondary" variant="body2">
          {firstLabel}
        </Typography>
        <Input
          value={values[firstName]}
          className={cn(s.input, {
            [s.withPadding]: firstLabel,
          })}
          dir="rtl"
          name={firstName}
          fullWidth
          onChange={onChange || onFirstChange}
        />
      </div>
      <div className={s.item}>
        <Typography className={s.label} color="secondary" variant="body2">
          {secondLabel}
        </Typography>
        <Input
          value={values[secondName]}
          className={cn(s.input, {
            [s.withPadding]: secondLabel,
          })}
          dir="rtl"
          name={secondName}
          fullWidth
          onChange={onChange || onSecondChange}
        />
      </div>
    </div>
  );
};
