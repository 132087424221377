import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../hooks';

export const PrivateRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthorized } = useAuth();

  return (
    <Route {...rest}>
      {isAuthorized ? children : <Redirect to="/login" />}
    </Route>
  );
};
