import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { useStore } from 'effector-react';
import {
  Button,
  FormItem,
  Input,
  Select,
  WithSidebar,
  IntervalInputs,
  FormControls,
  Form,
} from '../../ui';
import {
  $directions,
  $disciplines,
  $selectDirections,
  $selectDisciplines,
  $selectTeachers,
  $teachers,
} from '../../store';
import { deleteAllNonDigits, deleteAllSpaces, request } from '../../libs';
import { ISection, ISectionGroup } from '../../types';
import { WeekDaysTimeInputs } from '../../components';
import s from './Section.module.scss';
import {
  createNewScheduleTimes,
  createNewSectionGroup,
  createNewSectionGroupSchedule,
  defaultSectionGroups,
  periodicityTypes,
  recordTypes,
  WEEK_DAYS_NUMBERS,
} from './helpers';
import { SECTIONS } from '../../constants';
import { getSections } from '../../store/sections';

type FormData = {
  id: number;
  name: string;
  discipline: string;
  direction: string;
  recordType: number | string;
  minPlacesCount: number;
  maxPlacesCount: number;
  minAge: number;
  maxAge: number;
  cost: number;
  costDuration: number | string;
  teacher: string;
  sectionGroups: ISectionGroup[];
};

interface Props extends ISection {
  blocking: boolean;
  setBlocking: (...args: any[]) => void;
}

export const Section: FC<Props> = ({
  id,
  name,
  direction,
  discipline,
  sectionGroups,
  teacher,
  maxAge = 0,
  minAge = 0,
  maxPlacesCount = 0,
  minPlacesCount = 0,
  cost = 0,
  costDuration = 0,
  blocking,
  setBlocking,
}) => {
  const [activeGroup, setActiveGroup] = useState(
    sectionGroups?.[0]?.name ?? 'Группа 1'
  );
  const directionsOptions = useStore($selectDirections);
  const disciplines = useStore($disciplines);
  const disciplinesOptions = useStore($selectDisciplines);
  const directions = useStore($directions);
  const teachersOptions = useStore($selectTeachers);
  const teachers = useStore($teachers);

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        id,
        name,
        discipline: `${direction?.id}`,
        direction: `${discipline?.id}`,
        recordType: recordTypes?.[0].value,
        minPlacesCount,
        maxPlacesCount,
        minAge,
        maxAge,
        cost,
        costDuration,
        teacher: `${teacher?.id || teachersOptions?.[0]?.value}`,
        sectionGroups: sectionGroups?.length
          ? sectionGroups
          : defaultSectionGroups,
      },
      onSubmit,
      // validationSchema,
    });

  useEffect(() => {
    if (discipline && direction) {
      setFieldValue('discipline', `${discipline.id}`);
      setFieldValue('direction', `${direction.id}`);
    }
  }, [discipline, direction]);

  const sectionGroupsOptions = useMemo(
    () =>
      // @ts-ignore
      values.sectionGroups.map(({ name: _name }) => ({
        value: _name,
        text: _name,
      })),
    [values.sectionGroups]
  );

  const activeGroupIndex = values.sectionGroups.findIndex(
    ({ name: groupName }: ISectionGroup) => groupName === activeGroup
  );

  // @ts-ignore
  const activeSectionGroup: ISectionGroup = values.sectionGroups.find(
    ({ name: groupName }: ISectionGroup) => groupName === activeGroup
  );

  const activeSectionGroupSchedules =
    activeSectionGroup?.sectionGroupSchedules ?? [];

  const activeSectionGroupSchedulesWeekDays = [
    ...new Set(
      activeSectionGroupSchedules?.map(({ dayOfWeek }) => Number(dayOfWeek))
    ),
  ];

  const onActiveSectionGroupChange = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>): void => {
    setActiveGroup(value);
  };

  const onCostChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('cost', deleteAllNonDigits(deleteAllSpaces(value)));
  };

  const onChangeOnlyDigits = (
    fieldName: string,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ): void => {
    setFieldValue(fieldName, Number(deleteAllNonDigits(event.target.value)));
  };

  const onAddGroup = (): void => {
    const newSectionGroup = createNewSectionGroup(values.sectionGroups);
    const newSectionGroups = [...values.sectionGroups, newSectionGroup];

    setFieldValue('sectionGroups', newSectionGroups);
  };

  const onDeleteGroup = (): void => {
    // @ts-ignore
    const newSectionGroups = values.sectionGroups?.filter(
      ({ name: groupName }: ISectionGroup) => groupName !== activeGroup
    );

    if (newSectionGroups.length < 1) {
      newSectionGroups.push(createNewSectionGroup([]));
    }

    setActiveGroup(newSectionGroups?.[0]?.name ?? 'Группа 1');
    setFieldValue('sectionGroups', newSectionGroups);
  };

  const addNewWeekDay = (): void => {
    const innerSectionGroups: ISectionGroup[] = values.sectionGroups;
    const newDayOfWeek = WEEK_DAYS_NUMBERS.find(
      (dayOfWeek: number) =>
        !activeSectionGroupSchedulesWeekDays.includes(dayOfWeek)
    );

    if (!newDayOfWeek && newDayOfWeek !== 0) {
      return;
    }

    const newSectionGroups = innerSectionGroups.map(
      ({ name: groupName, sectionGroupSchedules, ...rest }) => {
        if (groupName === activeGroup) {
          return {
            ...rest,
            name: groupName,
            sectionGroupSchedules: sectionGroupSchedules.concat(
              createNewSectionGroupSchedule(newDayOfWeek)
            ),
          };
        }

        return {
          ...rest,
          name: groupName,
          sectionGroupSchedules,
        };
      }
    );

    setFieldValue('sectionGroups', newSectionGroups);
  };

  const addNewPeriod = useCallback(
    (scheduleId) => {
      const groupId = activeSectionGroup?.id || activeSectionGroup?.reactId;
      const innerSectionGroups: ISectionGroup[] = values.sectionGroups;
      const newSectionGroups = innerSectionGroups.map((group) => {
        if (groupId === group?.id || groupId === group?.reactId) {
          const newSectionGroupSchedules = group.sectionGroupSchedules?.map(
            (schedule) => {
              if (
                scheduleId === schedule?.id ||
                scheduleId === schedule?.reactId
              ) {
                return {
                  ...schedule,
                  sectionGroupScheduleTimes:
                    schedule.sectionGroupScheduleTimes.concat({
                      ...createNewScheduleTimes(),
                      sectionGroupSchedule: scheduleId,
                    }),
                };
              }

              return schedule;
            }
          );

          return {
            ...group,
            sectionGroupSchedules: newSectionGroupSchedules,
          };
        }

        return group;
      });

      setFieldValue('sectionGroups', newSectionGroups);
    },
    [values.sectionGroups]
  );

  const removePeriod = useCallback(
    (scheduleId, timesId) => {
      const groupId = activeSectionGroup?.id || activeSectionGroup?.reactId;
      const innerSectionGroups: ISectionGroup[] = values.sectionGroups;
      const newSectionGroups = innerSectionGroups.map((group) => {
        if (groupId === group?.id || groupId === group?.reactId) {
          const newSectionGroupSchedules = group.sectionGroupSchedules
            ?.map((schedule) => {
              if (
                scheduleId === schedule?.id ||
                scheduleId === schedule?.reactId
              ) {
                const newSectionGroupScheduleTimes =
                  schedule.sectionGroupScheduleTimes.filter(
                    ({ id: scheduleTimesId, reactId }) =>
                      scheduleTimesId !== timesId && reactId !== timesId
                  );

                if (newSectionGroupScheduleTimes?.length === 0) {
                  return null;
                }

                return {
                  ...schedule,
                  sectionGroupScheduleTimes: newSectionGroupScheduleTimes,
                };
              }

              return schedule;
            })
            .filter((item) => item);

          return {
            ...group,
            sectionGroupSchedules: newSectionGroupSchedules,
          };
        }

        return group;
      });

      setFieldValue('sectionGroups', newSectionGroups);
    },
    [values.sectionGroups]
  );

  async function onSubmit({
    discipline: formDiscipline,
    direction: formDirection,
    teacher: formTeacher,
    cost: formCost,
    ...formData
  }: FormData): Promise<void> {
    try {
      setBlocking(true);

      const currDirection = directions.find(
        ({ id: directionId }) => Number(formDirection) === directionId
      );
      const currDiscipline = disciplines.find(
        ({ id: disciplineId }) => Number(formDiscipline) === disciplineId
      );
      const currentTeacher = teachers.find(
        ({ id: teacherId }) => teacherId === Number(formTeacher)
      );

      const body = JSON.stringify({
        ...formData,
        discipline: currDiscipline,
        direction: currDirection,
        teacher: currentTeacher,
        cost: Number(formCost),
      });

      await request({
        url: `${SECTIONS}/${id}`,
        options: {
          method: 'PUT',
          body,
        },
      })();

      getSections();
    } catch ({ message }) {
      console.error(message);
    } finally {
      setBlocking(false);
    }
  }

  return (
    <WithSidebar sidebar={<div />}>
      <Form blocking={blocking} className={s.form} onSubmit={handleSubmit}>
        <FormItem
          error={!!errors.name && !!touched.name}
          label="Название услуги"
          helperText={!!errors.name && !!touched.name && errors.name}
        >
          <Input
            value={values.name}
            name="name"
            error={!!errors.name && !!touched.name}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem
          label="Направление"
          error={!!errors.direction && !!touched.direction}
          helperText={
            !!errors.direction && !!touched.direction && errors.direction
          }
        >
          <Select
            value={values.direction}
            name="direction"
            onChange={handleChange}
            options={directionsOptions}
          />
        </FormItem>
        <FormItem
          label="Дисциплина"
          error={!!errors.discipline && !!touched.discipline}
          helperText={
            !!errors.discipline && !!touched.discipline && errors.discipline
          }
        >
          <Select
            value={values.discipline}
            name="discipline"
            onChange={handleChange}
            options={disciplinesOptions}
          />
        </FormItem>
        <FormItem
          label="Тип записи"
          error={!!errors.recordType && !!touched.recordType}
          helperText={
            !!errors.recordType && !!touched.recordType && errors.recordType
          }
        >
          <Select
            name="recordType"
            value={values.recordType}
            onChange={handleChange}
            options={recordTypes}
          />
        </FormItem>
        <div className={s.doubled}>
          <FormItem
            error={!!errors.cost && !!touched.cost}
            label="Стоимость, ₽"
            helperText={!!errors.cost && !!touched.cost && errors.cost}
          >
            <Input
              value={Number(values.cost).toLocaleString()}
              name="cost"
              error={!!errors.cost && !!touched.cost}
              onChange={onCostChange}
            />
          </FormItem>
          <FormItem
            label="Переодичность"
            error={!!errors.costDuration && !!touched.costDuration}
            helperText={
              !!errors.costDuration &&
              !!touched.costDuration &&
              errors.costDuration
            }
          >
            <Select
              value={values.costDuration}
              name="costDuration"
              onChange={handleChange}
              options={periodicityTypes}
            />
          </FormItem>
        </div>
        <FormItem
          label="Возраст"
          error={
            (!!errors.minAge && !!touched.minAge) ||
            (!!errors.maxAge && !!touched.maxAge)
          }
          helperText={
            (!!errors.minAge || !!errors.maxAge) &&
            (!!errors.minAge || !!errors.maxAge) &&
            (errors.minAge || errors.maxAge)
          }
        >
          <IntervalInputs
            firstName="minAge"
            secondName="maxAge"
            onFirstChange={(event) => onChangeOnlyDigits('minAge', event)}
            onSecondChange={(event) => onChangeOnlyDigits('maxAge', event)}
            values={values}
          />
        </FormItem>
        <FormItem
          label="Количество мест"
          error={
            (!!errors.minPlacesCount && !!touched.minPlacesCount) ||
            (!!errors.maxPlacesCount && !!touched.maxPlacesCount)
          }
          helperText={
            (!!errors.minPlacesCount || !!errors.maxPlacesCount) &&
            (!!errors.minPlacesCount || !!errors.maxPlacesCount) &&
            (errors.minPlacesCount || errors.maxPlacesCount)
          }
        >
          <IntervalInputs
            firstName="minPlacesCount"
            secondName="maxPlacesCount"
            onFirstChange={(event) =>
              onChangeOnlyDigits('minPlacesCount', event)
            }
            onSecondChange={(event) =>
              onChangeOnlyDigits('maxPlacesCount', event)
            }
            values={values}
          />
        </FormItem>
        <FormItem
          label="Преподаватель"
          error={!!errors.teacher && !!touched.teacher}
          helperText={!!errors.teacher && !!touched.teacher && errors.teacher}
        >
          <Select
            value={values.teacher}
            name="teacher"
            onChange={handleChange}
            options={teachersOptions}
          />
        </FormItem>
        <FormItem
          label="Группа"
          error={!!errors.sectionGroups && !!touched.sectionGroups}
          helperText={
            !!errors.sectionGroups &&
            !!touched.sectionGroups &&
            (errors.sectionGroups as any)
          }
        >
          <div className={s.withControls}>
            <Select
              value={activeGroup}
              name="sectionGroups"
              onChange={onActiveSectionGroupChange}
              options={sectionGroupsOptions}
            />
            <FormControls
              onPlusClick={onAddGroup}
              onCloseClick={onDeleteGroup}
              plusTitle="Добавить группу"
              closeTitle="Удалить группу"
            />
          </div>
        </FormItem>
        <FormItem label="Дни работы">
          <WeekDaysTimeInputs
            data={activeSectionGroupSchedules}
            addNewWeekDay={addNewWeekDay}
            addPeriod={addNewPeriod}
            removePeriod={removePeriod}
            onChangeOnlyDigits={onChangeOnlyDigits}
            handleChange={handleChange}
            activeGroupIndex={activeGroupIndex}
          />
        </FormItem>
        <Button type="submit" className={s.submit} fullWidth variant="primary">
          Сохранить расписание
        </Button>
      </Form>
    </WithSidebar>
  );
};
