import { createEffect, createEvent, createStore, forward } from 'effector';
import { SECTIONS } from '../constants';
import { storeRequest } from '../libs';
import { ISection } from '../types';

export const $sections = createStore<ISection[]>([]);

export const getSections = createEvent();

export const getSectionsFx = createEffect(async () => {
  const res = await storeRequest(SECTIONS);

  return res;
});

forward({
  from: getSections,
  to: getSectionsFx,
});

$sections.on(getSectionsFx.doneData, (_, { data }) => data);
