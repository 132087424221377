import React, { ChangeEvent, FC } from 'react';
import { Button, Input, Select, Typography, FormControls } from '../../ui';
import { ISectionGroupSchedule } from '../../types';
import s from './WeekDaysTimeInputs.module.scss';
import { WEEK_DAYS_OPTIONS } from '../../pages/Section/helpers';

interface Props {
  data: ISectionGroupSchedule[];
  addNewWeekDay: (...args: any[]) => void;
  addPeriod: (...args: any[]) => void;
  removePeriod: (...args: any[]) => void;
  onChangeOnlyDigits: (...args: any[]) => void;
  handleChange: (...args: any[]) => void;
  activeGroupIndex: number;
}

export const WeekDaysTimeInputs: FC<Props> = ({
  data,
  addNewWeekDay,
  addPeriod,
  removePeriod,
  onChangeOnlyDigits,
  handleChange,
  activeGroupIndex,
}) => {
  return (
    <div className={s.wrap}>
      {data.map(
        ({ id, dayOfWeek, reactId, sectionGroupScheduleTimes }, index) => (
          <div className={s.root} key={id ?? reactId}>
            <div className={s.select}>
              <Select
                name={`sectionGroups[${activeGroupIndex}].sectionGroupSchedules[${index}].dayOfWeek`}
                value={dayOfWeek}
                options={WEEK_DAYS_OPTIONS}
                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                  onChangeOnlyDigits(
                    `sectionGroups[${activeGroupIndex}].sectionGroupSchedules[${index}].dayOfWeek`,
                    event
                  )
                }
              />
            </div>
            <div className={s.schedules}>
              {sectionGroupScheduleTimes?.map(
                (
                  {
                    startTime,
                    endTime,
                    reactId: scheduleTimesReactId,
                    id: scheduleTimesId,
                  },
                  scheduleIndex
                ) => (
                  <div
                    className={s.input}
                    key={scheduleTimesId ?? scheduleTimesReactId}
                  >
                    <Input
                      name={`sectionGroups[${activeGroupIndex}].sectionGroupSchedules[${index}].sectionGroupScheduleTimes[${scheduleIndex}].startTime`}
                      onChange={handleChange}
                      value={startTime}
                      mask="99:99"
                    />
                    <Typography variant="h3">–</Typography>
                    <Input
                      name={`sectionGroups[${activeGroupIndex}].sectionGroupSchedules[${index}].sectionGroupScheduleTimes[${scheduleIndex}].endTime`}
                      onChange={handleChange}
                      value={endTime}
                      mask="99:99"
                    />
                    <div className={s.input_control}>
                      <FormControls
                        onPlusClick={
                          scheduleIndex === 0
                            ? () => {
                                addPeriod(id || reactId);
                              }
                            : undefined
                        }
                        plusTitle={
                          scheduleIndex === 0 ? 'Добавить период' : undefined
                        }
                        closeTitle="Удалить период"
                        onCloseClick={() =>
                          removePeriod(
                            id || reactId,
                            scheduleTimesId || scheduleTimesReactId
                          )
                        }
                      />
                    </div>
                  </div>
                )
              )}
              {sectionGroupScheduleTimes?.length === 0 && (
                <FormControls
                  onPlusClick={() => {
                    addPeriod(id || reactId);
                  }}
                  plusTitle="Добавить период"
                />
              )}
            </div>
          </div>
        )
      )}
      {data.length <= 6 && (
        <Button
          onClick={addNewWeekDay}
          type="button"
          fullWidth
          variant="outline"
        >
          Добавить новый день
        </Button>
      )}
    </div>
  );
};
