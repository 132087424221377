import React, { FC } from 'react';
import { useModal, Modal } from '../../libs';

import { Button } from '../../ui';
import { NewSectionForm } from './NewSectionForm';

import s from './Main.module.scss';

export const MainSidebar: FC = () => {
  const { isActive, toggleModal } = useModal();

  return (
    <div className={s.sidebar}>
      <Button onClick={toggleModal}>Добавить услугу</Button>
      <Modal title="Новая услуга" isActive={isActive} toggleModal={toggleModal}>
        <NewSectionForm />
      </Modal>
    </div>
  );
};
