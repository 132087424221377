import { toast } from 'react-toastify';
import { HOST } from '../constants';
import { delay as promiseDelay } from './delay';
import { LocalStorageHelper } from './localStorage';

export type APIResponseModel<T> = {
  result: T;
  success: boolean;
  errorCode: number;
  error?: null | string;
};

export interface IRequest {
  url: RequestInfo;
  options?: RequestInit;
  delay?: number;
  successNotify?: string;
  noHeaders?: boolean;
}

const ls = new LocalStorageHelper('auth');

const defaultOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

export function request<T>({ url, options, delay, successNotify }: IRequest) {
  return async (): Promise<T | null> => {
    try {
      if (delay) {
        await promiseDelay(delay);
      }

      const fetchOptions =
        typeof options?.body === 'string'
          ? {
              ...defaultOptions,
              ...options,
              headers: {
                ...defaultOptions.headers,
                ...options.headers,
                Authorization: `Bearer ${ls.get('jwt')}`,
              },
            }
          : {
              ...options,
              headers: {
                Authorization: `Bearer ${ls.get('jwt')}`,
              },
            };

      const response = await fetch(HOST + url, fetchOptions);
      const contentType = response.headers.get('content-type');

      if (response.status === 204) {
        return null;
      }

      let data;

      if (contentType?.includes('json')) {
        data = await response.json();
      }

      if (contentType?.includes('text')) {
        data = await response.text();
      }

      if (!response.ok) {
        if (response.status === 401 && response.statusText === 'Unauthorized') {
          ls.set('isAuthorized', false);
          ls.remove('jwt');
          window.location.href = '/login';
        }

        const errors = data?.errors && Object.values(data.errors);

        throw new Error(
          errors?.[0] ?? data?.errorText ?? data ?? 'Произошла ошибка'
        );
      }

      if (successNotify) {
        toast.success(successNotify);
      }

      return data;
    } catch ({ message }) {
      const errorMessage =
        message === 'Failed to fetch' ? 'Произошла ошибка' : message;
      toast.error(errorMessage);
      return Promise.reject(new Error(errorMessage));
    }
  };
}

export const storeRequest = async (url: string): Promise<any> => {
  const res = await fetch(HOST + url, {
    headers: {
      Authorization: `Bearer ${ls.get('jwt')}`,
    },
  });

  if (res.status === 401 && res.statusText === 'Unauthorized') {
    ls.set('isAuthorized', false);
    ls.remove('jwt');
    window.location.href = '/login';
    throw new Error('Ошибка авторизации');
  }

  return res.json();
};
