import { createEvent, forward, Effect } from 'effector';
import { toast } from 'react-toastify';
import { getDirections, getDirectionsFx } from './directions';
import { getDisciplines, getDisciplinesFx } from './disciplines';
import { getTeachers, getTeachersFx } from './teachers';
import { getOrganization, getOrganizationFx } from './organization';
import { getSections, getSectionsFx } from './sections';
import { getCities, getCitiesFx } from './city';

const watchFailEffect = (effect: Effect<any, any>): void => {
  effect?.fail?.watch(({ error }) => {
    console.error(error.message);
    const errorMessage =
      error.message === 'Failed to fetch' ? 'Произошла ошибка' : error.message;
    toast.error(errorMessage);
  });
};

const watchFailEffects = (effects: Effect<any, any>[]): void => {
  effects?.forEach(watchFailEffect);
};

watchFailEffects([
  getSectionsFx,
  getOrganizationFx,
  getTeachersFx,
  getDisciplinesFx,
  getDirectionsFx,
  getCitiesFx,
]);

export const getData = createEvent();

forward({
  from: getData,
  to: [
    getDirections,
    getDisciplines,
    getTeachers,
    getOrganization,
    getSections,
    getCities,
  ],
});
