import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import uniqueId from 'lodash/uniqueId';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../constants';

export const createMonthCells = (currentMonth: Date | number): Date[][] => {
  const month = new Date(currentMonth);
  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(monthStart);
  const weekStart = startOfWeek(monthStart, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(monthEnd, { weekStartsOn: 1 });
  const rows = [];
  let days = [];
  let day = weekStart;

  while (day <= weekEnd) {
    for (let i = 0; i < 7; i += 1) {
      days.push(day);
      day = addDays(day, 1);
    }
    rows.push(days);
    days = [];
  }

  return rows;
};

export type TFormData = {
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  gender: string | number;
};

export type TFormDataKeys = keyof TFormData;

export type TNewEntryInitialData = {
  reactId: string | number;
  label: string;
  name: TFormDataKeys;
  type: 'input' | 'select' | 'textarea';
};

export const newEntryFormData: TNewEntryInitialData[] = [
  {
    reactId: uniqueId(),
    label: 'Фамилия',
    type: 'input',
    name: 'lastName',
  },
  {
    reactId: uniqueId(),
    label: 'Имя',
    type: 'input',
    name: 'firstName',
  },
  {
    reactId: uniqueId(),
    label: 'Отчетсво',
    type: 'input',
    name: 'middleName',
  },
  {
    reactId: uniqueId(),
    label: 'Пол',
    type: 'select',
    name: 'gender',
  },
  {
    reactId: uniqueId(),
    label: 'Номер телефона',
    type: 'input',
    name: 'phone',
  },
];

export const newEntryValidationSchema = yup.object({
  lastName: yup.string().required(REQUIRED_FIELD),
  firstName: yup.string().required(REQUIRED_FIELD),
  middleName: yup.string().required(REQUIRED_FIELD),
  phone: yup.string().required(REQUIRED_FIELD),
  gender: yup.string().required(REQUIRED_FIELD),
});

export const childDeclension = ['ученик', 'ученика', 'учеников'];
