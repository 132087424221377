import React, { FC } from 'react';
import cn from 'classnames';
import s from './Link.module.scss';

interface Props {
  href: string;
  color?: 'primary' | 'link';
  className?: string;
}

export const Link: FC<Props> = ({
  href,
  children,
  color = 'primary',
  className,
}) => {
  return (
    <a
      className={cn(s.root, className)}
      style={{ color: `var(--color-${color})` }}
      href={href}
    >
      {children}
    </a>
  );
};
