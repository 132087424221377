import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Link } from 'react-router-dom';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import cn from 'classnames';
import { Button, EmptyPage, OrganisationHeader, Typography } from '../../ui';
import { $organization } from '../../store';
import s from './Contacts.module.scss';
import { formatStringToPhoneLink, formatStringToPhoneNumber } from '../../libs';

export const Preview: FC = () => {
  const organization = useStore($organization);

  if (!organization) {
    return (
      <EmptyPage
        title="У вас нет организации"
        subtitle="Данные отсутвуют. Возможно, произошла ошибка"
      />
    );
  }

  return (
    <div className={s.wrap}>
      {organization && <OrganisationHeader {...organization} />}
      <div className={s.content}>
        <div className={cn(s.content_item, s.content_header)}>
          <Typography bold variant="body2" color="secondary">
            Контакты
          </Typography>
          <Link to="/contacts/edit">
            <Button variant="link">Редактировать</Button>
          </Link>
        </div>
        <div className={s.content_item}>
          <Typography
            className={s.content_subheading}
            variant="body2"
            color="secondary"
          >
            Телефон для связи
          </Typography>
          {organization?.phone ? (
            <a href={`tel:${formatStringToPhoneLink(organization?.phone)}`}>
              <Typography variant="subheading1">
                {formatStringToPhoneNumber(organization?.phone)}
              </Typography>
            </a>
          ) : (
            <Typography>Телефон отсутствует</Typography>
          )}
        </div>
        <div className={s.content_item}>
          <Typography
            className={s.content_subheading}
            variant="body2"
            color="secondary"
          >
            Адрес
          </Typography>
          {organization?.address && organization?.city?.name ? (
            <Typography variant="subheading1">
              {`г. ${organization.city.name}, ${organization?.address}`}
            </Typography>
          ) : (
            <Typography>Адрес отсутствует</Typography>
          )}
        </div>
        {organization?.latitude && organization?.longitude && (
          <div className={cn(s.content_item, s.map, s.content_map)}>
            <YMaps>
              <Map
                width="100%"
                height="100%"
                defaultState={{
                  center: [
                    Number(organization.latitude),
                    Number(organization.longitude),
                  ],
                  controls: [],
                  zoom: 17,
                }}
              >
                <ZoomControl options={{ float: 'right' }} />
                <Placemark
                  geometry={[
                    Number(organization.latitude),
                    Number(organization.longitude),
                  ]}
                  properties={{
                    iconCaption: organization.address,
                  }}
                  modules={['geoObject.addon.balloon']}
                />
              </Map>
            </YMaps>
          </div>
        )}
      </div>
    </div>
  );
};
