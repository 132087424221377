/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import cn from 'classnames';

import s from './Button.module.scss';

enum Size {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
}

enum Variant {
  Primary = 'primary',
  Outline = 'outline',
  Link = 'link',
  Ghost = 'ghost',
  Danger = 'danger',
  Error = 'error',
}

type SizeType = 'xl' | 'l' | 'm' | 's';

type VariantType =
  | 'primary'
  | 'outline'
  | 'link'
  | 'ghost'
  | 'danger'
  | 'error';

interface ButtonProps {
  children: any;
  onClick?: (...args: any[]) => void;
  icon?: any;
  size?: SizeType;
  variant?: VariantType;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  uppercase?: boolean;
  [x: string]: any;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  icon,
  size = Size.L,
  variant = Variant.Primary,
  type = 'button',
  fullWidth,
  uppercase,
  ...rest
}) => {
  const buttonClassName = cn(s.button, {
    [s.extraLarge]: size === Size.XL,
    [s.large]: size === Size.L,
    [s.medium]: size === Size.M,
    [s.small]: size === Size.S,
    [s.primary]: variant === Variant.Primary,
    [s.outline]: variant === Variant.Outline,
    [s.link]: variant === Variant.Link,
    [s.ghost]: variant === Variant.Ghost,
    [s.danger]: variant === Variant.Danger,
    [s.error]: variant === Variant.Error,
    [s.button_fullWidth]: fullWidth,
    [s.uppercase]: uppercase,
    [className as string]: className as string,
  });

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      tabIndex={0}
      type={type}
      {...rest}
    >
      <span tabIndex={-1}>{children}</span>
      {icon && icon}
    </button>
  );
};
