import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { request, useAuth } from '../../libs';
import {
  Typography,
  FormItem,
  Input,
  Button,
  Container,
  BlockUI,
} from '../../ui';
import { validationSchema } from './validationSchema';
import { API_VERSION, LOGIN } from '../../constants';

import { ReactComponent as Logo } from '../../assets/images/Logo-big.svg';

import s from './Login.module.scss';
import { IAuth } from '../../types';

type TFormData = {
  email: string;
  password: string;
};

export const Login: FC = () => {
  const [isSending, setSending] = useState(false);
  const history = useHistory();
  const { login } = useAuth();

  const { values, touched, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  async function onSubmit({ email, password }: TFormData): Promise<void> {
    try {
      setSending(true);

      const res = await request<IAuth>({
        url: `${LOGIN}?email=${email}&password=${password}&version=${API_VERSION}`,
        options: {
          method: 'POST',
        },
      })();

      login(res as IAuth);

      history.push('/');
    } catch ({ message }) {
      console.error(message);
    } finally {
      setSending(false);
    }
  }

  return (
    <div className={s.root}>
      <Container>
        <div className={s.logo}>
          <Logo />
          <Typography color="white" uppercase>
            Образовательная карта
          </Typography>
        </div>

        <BlockUI blocked={isSending} borderRadius={8}>
          <form onSubmit={handleSubmit} className={s.form}>
            <Typography variant="subheading1">Личный кабинет</Typography>
            <div className={s.inputs}>
              <FormItem
                error={!!errors.email && touched.email}
                label="Электронная почта"
                helperText={!!errors.email && touched.email && errors.email}
              >
                <Input
                  value={values.email}
                  name="email"
                  error={!!errors.email && touched.email}
                  onChange={handleChange}
                  variant="standart"
                />
              </FormItem>
              <FormItem
                error={!!errors.password && touched.password}
                label="Пароль"
                helperText={!!errors.email && touched.email && errors.password}
              >
                <Input
                  value={values.password}
                  name="password"
                  error={!!errors.password && touched.password}
                  onChange={handleChange}
                  variant="standart"
                  type="password"
                />
              </FormItem>
            </div>
            <div className={s.buttons}>
              <Button
                style={{ borderRadius: 8 }}
                type="submit"
                uppercase
                size="m"
                fullWidth
              >
                Войти
              </Button>
            </div>
          </form>
        </BlockUI>
      </Container>
    </div>
  );
};
