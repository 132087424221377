import React, { FC } from 'react';

import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-cross.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right.svg';

import s from './Button.module.scss';

const RENDER_OBJ = {
  close: <CloseIcon />,
  plus: <PlusIcon />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
};

interface IIconButton {
  type: 'close' | 'plus' | 'arrowLeft' | 'arrowRight';
  onClick?: (...args: any[]) => void;
}

export const IconButton: FC<IIconButton> = ({ type, onClick }) => {
  return (
    <div role="button" tabIndex={0} onClick={onClick} className={s.iconButton}>
      {RENDER_OBJ[type]}
    </div>
  );
};
